import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Stack, Dialog, Grid, Select, MenuItem, FormControl, InputLabel } from '@mui/material';
import { DialogActions, DialogContent, Box, DialogTitle, OutlinedInput } from '@mui/material';
import { TextField, Tooltip, IconButton, Button, ListItemText, Checkbox } from '@mui/material';
import { Delete, Edit } from '@mui/icons-material';
import { useConfirm } from 'material-ui-confirm';
import Header from '../../components/MainLayout/Header';
import validator from 'validator';
import AccountAuth from '../../state/APIConnect/Account';
import { toast } from 'react-toastify';

const UsersPage = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [validationErrors, setValidationErrors] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const confirm = useConfirm();

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleCreateNewRow = (values) => {
        tableData.push(values);
        setTableData([...tableData]);
    };

    const handleDeleteRow = useCallback((row) => {
        confirm({ title: `Are you sure you want to delete?`, description: `This will permanently delete: ${row.original.name}.`, cancellationText: `No`, confirmationText: `Yes` })
            .then(() => console.log("Deleted."))
            .catch(() => console.log("Deletion cancelled."))
        //send api delete request here, then refetch or update local table data for re-render
        tableData.splice(row.index, 1);
        setTableData([...tableData]);
    }, [confirm, tableData]);


    const handleSaveRowEdits = async ({ exitEditingMode, row, values }) => {
        // try {
        //     const resp = await NSPMonitorAuth.UpsertActivity(values);
        //     if (!resp.is_error || resp.content.isSuccess) {
        //         toast.success(resp.content.message, { position: "top-right", });
        //         if (!Object.keys(validationErrors).length) {
        //             tableData[row.index] = values;
        //             setTableData([...tableData]);
        //             exitEditingMode();
        //         }
        //     } else {
        //         toast.error(resp.content.message, { position: "top-right", })
        //     }
        // } catch (error) {
        //     toast.error(error, { position: "top-right", })
        // }
    };

    const GetAllUsers = async () => {
        if (!tableData.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            const res = await AccountAuth.GetAllUsersWithRoles(null, pagination.pageIndex, pagination.pageSize);
            setTableData(res.content.items);
            setRowCount(res.content.totalItems)
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        } finally {
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        }
    }

    useEffect(() => {
        GetAllUsers();
    }, [tableData.length, pagination.pageIndex, pagination.pageSize])

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = true;
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({ ...validationErrors, [cell.id]: `${cell.column.columnDef.header} is required`, });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({ ...validationErrors, });
                    }
                },
            };
        },
        [validationErrors],
    );


    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 10,
            },
            {
                accessorKey: 'firstName',
                header: 'First Name',
                size: 20,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'surname',
                header: 'Surname',
                size: 20,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'mobileNumber',
                header: 'Mobile Number',
                size: 20,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'emailAddress',
                header: 'Email Address',
                size: 20,
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'lastLogin',
                header: 'Last Login',
                size: 50,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return date.toDateString();
                },
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'userRoles',
                header: 'Roles',
                size: 80,
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
        ], [getCommonEditTextFieldProps],
    );

    return (
        <Box m="0.5rem 0.5rem">
            <MaterialReactTable displayColumnDefOptions={{ 'mrt-row-actions': { muiTableHeadCellProps: { align: 'center', }, size: 120, }, }}
                columns={columns}
                muiTable
                data={tableData}
                editingMode="modal" //default
                initialState={{ columnVisibility: { id: false } }} //hide firstName column by default
                enableColumnOrdering
                enableEditing
                enablePagination
                rowCount={rowCount}
                manualPagination
                onPaginationChange={setPagination}
                muiTableBodyCellEditTextFieldProps={{ multiline: true }}
                onEditingRowSave={handleSaveRowEdits}
                onEditingRowCancel={handleCancelRowEdits}
                state={{
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                }}
                positionGlobalFilter="left"
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => table.setEditingRow(row)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <>
                        <Button
                            color="primary"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained" >
                            Add New User
                        </Button>
                        <Header subtitle="User" />
                    </>
                )} />

            <CreateNewAccountModal
                columns={columns}
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                onSubmit={handleCreateNewRow} />
        </Box>
    )
}

export const CreateNewAccountModal = ({ open, onClose }) => {
    const [firstName, setFirstName] = useState('');
    const [surname, setSurname] = useState('');
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [userRoles, setUserRoles] = useState([]);
    const [validationError, setValidationError] = useState("");   
    const [roles, setRoles] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const handleChange = (event) => {
        const { target: { value }, } = event;
        setUserRoles(typeof value === 'string' ? value.split(',') : value);        
    };


    const isFormValid = () => {
        let valid = true;
        // if (name === '' || name.length < 7) {
        //     valid = false;
        // }
        return valid;
    }

    const IsvalidEmail = () => {
        if (validator.isEmail(emailAddress)) {
            return true;
        }
        setValidationError('Invalid Email Address')
        return false;
    }

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            debugger;
            setIsLoading(true);
            const formData = new FormData();
            formData.append('firstName', firstName);
            formData.append('surname', surname);
            formData.append('mobileNumber', mobileNumber);
            formData.append('emailAddress', emailAddress);
            formData.append('categoryId', 0);
            formData.append('roles', userRoles);           
            await AccountAuth.adminAddUser(formData).then((response) => {
                setIsLoading(false);
                if (response.content === null) {
                    toast.error(response.error_content.message, { position: "top-right", })
                }
                else {
                    toast.success(response.content.message, { position: "top-right", });
                    setFirstName('');
                    setSurname('');
                    setMobileNumber('')
                    setEmailAddress('')
                    setUserRoles([]);
                }
            })
        } catch (error) {
            toast.error(error.message, { position: "top-right", })
        }
    };

    const GetAllRoles = async () => {
        const res = await AccountAuth.GetAllForAdminRoles();
        setRoles(res.content);
    }

    useEffect(() => {
        // GetApps();
        GetAllRoles();

    }, [])


    return (
        <Dialog open={open} fullWidth>
            <DialogTitle textAlign="center">Add New User</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>
                    <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem', paddingTop: '10px' }}>
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Roles</InputLabel>
                                    <Select multiple
                                        value={userRoles}
                                        onChange={handleChange}
                                        input={<OutlinedInput label="Roles" />}
                                        renderValue={(selected) => selected.join(', ')}>
                                        {roles.map((role) => (
                                            <MenuItem key={role.roleId} value={role.roleName}>
                                                <Checkbox color="primary" checked={userRoles.indexOf(role.roleName) > -1} />
                                                <ListItemText primary={role.roleName} />
                                            </MenuItem>
                                        ))}
                                    </Select>
                                </FormControl>

                                {/* <FormControl fullWidth>
                                    <InputLabel>Roles</InputLabel>
                                    <Select defaultValue={0} label="Roles" value={roleId} renderValue={(selected) => selected.join(', ')}>
                                        <MenuItem value={0}><em>None</em></MenuItem>
                                        {roles.map((role) => (
                                            <MenuItem key={role.roleId} value={role.roleId}>
                                                <CheckBox checked={setRoleId.indexOf(role) > -1} />
                                                <ListItemText primary={role} />
                                            </MenuItem>
                                        ))}
                                        {roles.map(role => { return (<MenuItem key={role.roleId} value={role.roleId}> {role.roleName} </MenuItem>) })}
                                    </Select>
                                </FormControl> */}
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="First Name" value={firstName} onChange={e => setFirstName(e.target.value)} />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField fullWidth label="Surname" value={surname} onChange={e => setSurname(e.target.value)} />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required
                                    type="tel"
                                    fullWidth
                                    value={mobileNumber}
                                    onChange={e => setMobileNumber(e.target.value)}
                                    label="Mobile Number" />
                            </Grid>

                            <Grid item xs={12}>
                                <TextField required
                                    type="email"
                                    value={emailAddress}
                                    fullWidth
                                    onChange={e => setEmailAddress(e.target.value)}
                                    label="Email Address" />
                                {!IsvalidEmail && { validationError }}
                            </Grid>


                        </Grid>
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="primary" onClick={handleSubmit} variant="contained" disabled={!isFormValid()}>
                    Add New User
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default UsersPage