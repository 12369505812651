import axios from 'axios';
import Auth from '../hooks/Auth';
import Api from './ApiConfig';
import AccountAuth from './APIConnect/Account';

export default class RestUtilities {

    static get(url) {
        return RestUtilities.request('GET', url);
    }

    static getBlob(url, fileName) {
        return RestUtilities.getBlobs(url, fileName);
    }

    static delete(url, data) {
        return RestUtilities.request('DELETE', url, data);
    }

    static put(url, data) {
        return RestUtilities.request('PUT', url, data);
    }

    static post(url, data) {
        return RestUtilities.request('POST', url, data);
    }

    static patch(url, data) {
        return RestUtilities.request('PATCH', url, data);
    }

    static postForm(url, data) {
        return RestUtilities.requestFormData('POST', url, data);
    }

    static request(method, url, data) {
        // let isJsonResponse = false;
        let isBadRequest = false;
        let body = data;
        let isError = false;
        const headers = new Headers();
        headers.set('Authorization', `Bearer ${Auth.getToken()}`);
        headers.set('Accept', 'application/json');
        headers.set('Access-Control-Allow-Origin', '*');

        if (data) {
            if (data.FormFiles) {
                headers.set('Content-Type', 'multipart/form-data');
                body = JSON.stringify(data);
            }
            else if (typeof data === 'object') {
                headers.set('Content-Type', 'application/json');
                body = JSON.stringify(data);
            } else {
                headers.set('Content-Type', 'application/x-www-form-urlencoded');
            }

        }

        return fetch(`${Api.baseUrl}/${url}`, { mode: 'cors', method, headers, body, })
            .then((response) => {
                if (response.status === 401) {
                    // Unauthorized; redirect to sign-in
                    AccountAuth.signOut();
                    window.location.replace('/login');
                }
                isError = response.status === 404;
                isBadRequest = response.status === 400;
                const responseContentType = response.headers.get('content-type');
                if (
                    responseContentType
                    && responseContentType.indexOf('application/json') !== -1
                ) {
                    // isJsonResponse = true;
                    return response.json();
                }
                return response.text();
            }).then((responseContent) => {
                const response = {
                    is_error: isError,
                    error_content: isBadRequest ? responseContent : null,
                    content: isBadRequest ? null : responseContent,
                };
                return response;
            });
    }

    static requestBlob(url) {
        axios(`${Api.baseUrl}/${url}`, {
            method: 'GET',
            responseType: 'blob', // Force to receive data in a Blob Format
            headers: {
                Authorization: `Bearer ${Auth.getToken()}`,
            },
        }).then((response) => {
            // Create a Blob from the PDF Stream
            const file = new Blob(
                [response.data],
                { type: 'application/pdf' },
            );
            // Build a URL from the file
            const fileURL = URL.createObjectURL(file);
            // Open the URL on new Window
            window.open(fileURL);
        }).catch(() => {

        });
    }

    static getBlobs(url, fileName) {
        const headers = new Headers();
        headers.set('Authorization', `Bearer ${Auth.getToken()}`);
        headers.set('Accept', 'application/json');
        headers.set('Access-Control-Allow-Origin', '*');

        fetch(`${Api.baseUrl}/${url}`, { mode: 'cors', headers })
            .then((res) => {
                return res.blob();
            }).then((blob) => {
                const href = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = href;
                link.setAttribute('download', fileName); //or any other extension
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            })
            .catch((err) => {
                return Promise.reject({ Error: 'Something Went Wrong', err });
            })
    }

    static async requestFormData(method, url, data) {
        let isBadRequest = false;
        const headers = new Headers();

        const authToken = Auth.getToken();
        if (authToken) {
            headers.set('Authorization', `Bearer ${authToken}`);
        }

        try {
            const response = await fetch(`${Api.baseUrl}/${url}`, { method: method, body: data, headers: headers });
            if (response.status === 401) {
                AccountAuth.signOut();
                window.location.replace('/login');
            }
            isBadRequest = response.status === 400;

            const responseContentType = response.headers.get('content-type');
            const responseContent = responseContentType && responseContentType.indexOf('application/json') !== -1
                ? await response.json()
                : await response.text();

            const result = {
                is_error: isBadRequest,
                error_content: isBadRequest ? responseContent : null,
                content: isBadRequest ? null : responseContent,
            };
            return result;
        } catch (error) {
            console.error('Error:', error);
            throw error; // You may want to handle errors further up the call chain
        }
    }



    // static requestFormData(method, url, data) {
    //     let isBadRequest = false;
    //     const headers = new Headers();

    //     const authToken = Auth.getToken();
    //     if (authToken) {
    //         headers.set('Authorization', `Bearer ${authToken}`);
    //     }
    //     return fetch(`${Api.baseUrl}/${url}`, { method: method, body: data, headers: headers })
    //         .then((response) => {
    //             if (response.status === 401) {
    //                 AccountAuth.signOut();
    //                 window.location.replace('/login');
    //             }
    //             isBadRequest = response.status === 400;
    //             const responseContentType = response.headers.get('content-type');
    //             if (responseContentType && responseContentType.indexOf('application/json') !== -1) {
    //                 return response.json();
    //             }
    //             return response.text();
    //         })
    //         .then((responseContent) => {
    //             const response = {
    //                 is_error: isBadRequest,
    //                 error_content: isBadRequest ? responseContent : null,
    //                 content: isBadRequest ? null : responseContent,
    //             };
    //             return response;
    //         })
    //         .catch((error) => {
    //             console.error('Error:', error);
    //             throw error; // You may want to handle errors further up the call chain
    //         });
    // }




    // static requestFormData(method, url, data) {
    //     // let isJsonResponse = false;
    //     let isBadRequest = false;
    //     const headers = new Headers();
    //     headers.set('Authorization', `Bearer ${Auth.getToken()}`);
    //     headers.set('Content-Type', 'multipart/form-data');


    //     return axios(`${Api.baseUrl}/${url}`, { method: method, data, headers, }).then((response) => {
    //         console.log("🚀 ~ file: RestUtilities.js:125 ~ RestUtilities ~ requestFormData ~ response:", response)
    //         if (response.status === 401) {
    //             // Unauthorized; redirect to sign-in
    //             // Auth.removeToken();
    //             window.location.replace('/login');
    //         }

    //         isBadRequest = response.status === 400;

    //         const responseContentType = response.headers.get('content-type');
    //         if (
    //             responseContentType
    //             && responseContentType.indexOf('application/json') !== -1
    //         ) {
    //             // isJsonResponse = true;
    //             return response.json();
    //         }
    //         return response.text();
    //     }).then((responseContent) => {
    //         const response = {
    //             is_error: isBadRequest,
    //             error_content: isBadRequest ? responseContent : null,
    //             content: isBadRequest ? null : responseContent,
    //         };
    //         return response;
    //     });
    // }
}
