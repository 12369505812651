import React, { useState } from 'react';
import { useNavigate, useLocation, Link } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Paper, CircularProgress, Typography, Checkbox, Dialog, DialogActions, DialogContent, Box } from '@mui/material';
import { Grid, TextField, CssBaseline, Button, Avatar, FormControlLabel, DialogTitle, Stack, Alert } from '@mui/material';
import { blue } from '@mui/material/colors';
import useAuth from '../../hooks/useAuth';
import AccountAuth from '../../state/APIConnect/Account';
import { toast } from 'react-toastify';
import validator from 'validator';
import RaiseMyHandImageofWoman from '../../assests/users/RaiseMyHand.jpeg'
import { AppSettings } from '../../AppSettings';

const SideLogin = () => {
    const [username, setUsername] = useState('');
    const [password, setPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [openForgetPasswordDialog, setOpenForgetPasswordDialog] = useState(false);
    const [errMsg, setErrMsg] = useState("");
    const navigate = useNavigate();
    const location = useLocation();
    const from = location.state?.from?.pathname || "/";
    const { setAuth } = useAuth();

    const isFormValid = () => {
        let valid = true;
        if (username === '' || password === '') {
            valid = false;
        }
        return valid;
    }
        

    const handleClickOpen = () => {
        setOpenForgetPasswordDialog(true);
    };

    const handleSubmit = (event) => {
        event.preventDefault();
        try {
            setIsLoading(true);
            AccountAuth.signIn(username, password, AppSettings.App.NSPMonitoring).then((response) => {
                setIsLoading(false);
                setPassword('');
                if (response.is_error) {
                    setErrMsg(response.content.message);
                    toast.error(response.content.message, { position: "top-right", })
                } else {
                    const accessToken = response?.content.token;
                    const firstName = response?.content.firstName;
                    const surname = response?.content.surname;
                    setAuth(({ username, accessToken, firstName, surname }));
                    navigate(from, { replace: true });
                }
            })
        } catch (error) {
            setIsLoading(false);
            setPassword('');
            if (!error?.response) {
                setErrMsg('No Server Response');
            } else if (error.response?.status === 400) {
                setErrMsg('Missing Username or Password');
            } else if (error.response?.status === 401) {
                setErrMsg('Unauthorized');
            } else {
                setErrMsg(error.response.data.message);
            }
            toast.error(errMsg, { position: "top-right", })
        }

    };

    return (
        <>
            <Grid container component="main" sx={{ height: '90vh' }}>
                <CssBaseline />
                <Grid item xs={false} sm={4} md={7}
                    sx={{
                        // backgroundImage: 'url(https://source.unsplash.com/random/?city,night)',
                        backgroundImage: `url(${RaiseMyHandImageofWoman})`, // Use the imported image
                        backgroundRepeat: 'no-repeat',
                        backgroundColor: (t) =>
                            t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                        backgroundSize: 'cover',
                        backgroundPosition: 'center',
                    }} />
                <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square >
                    <Box sx={{ my: 4, mx: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                        <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                            <LockOutlinedIcon />
                        </Avatar>
                        <Typography component="h1" variant="h5" color="inherit">
                            Sign in
                        </Typography>

                        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ '& .MuiTextField-root': { m: 1, } }} autoComplete="off">

                            <TextField fullWidth
                                label="Email Address"
                                id="fullWidth"
                                required
                                value={username}
                                onChange={e => setUsername(e.target.value)} />

                            <TextField
                                fullWidth
                                id="outlined-password-input"
                                label="Password"
                                type="password"
                                autoComplete="current-password"
                                value={password}
                                onChange={e => setPassword(e.target.value)} />

                            <FormControlLabel control={<Checkbox value="remember" color="primary" />} label="Remember me" />

                            <Button disabled={isLoading || !isFormValid()}
                                type="submit"
                                fullWidth
                                variant="contained"
                                sx={{ mt: 3, mb: 2 }}>
                                Sign In
                                {isLoading && (
                                    <CircularProgress size={24} sx={{ color: blue[500], position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px', }} />
                                )}
                            </Button>

                            <Grid container>
                                <Grid item xs>
                                    <Link onClick={handleClickOpen} variant="body2" className="removeLink">
                                        Forgot password?
                                    </Link>
                                </Grid>
                                <Grid item>
                                    <Link to={"/SignUp"} variant="body2" className="removeLink">
                                        {"Don't have an account? Sign Up"}
                                    </Link>
                                </Grid>
                            </Grid>
                            {/* <AccountFooter sx={{ mt: 5 }} /> */}
                        </Box>
                    </Box>
                </Grid>
            </Grid >
            <ForgotPasswordModal open={openForgetPasswordDialog} onClose={() => setOpenForgetPasswordDialog(false)} appId={AppSettings.App.NSPMonitoring} />
        </>
    )
}

export const ForgotPasswordModal = ({ open, onClose, appId }) => {
    const [emailAddress, setEmailAddress] = useState('');
    const [message, setMessage] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [isSuccess, setIsSuccess] = useState(false);

    const isFormValid = () => {
        let valid = true;
        if (emailAddress === '' || emailAddress.length < 7) {
            valid = false;
        }
        if (!validator.isEmail(emailAddress)) {
            valid = false;
        }
        return valid;
    }


    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            setIsLoading(true);
            await AccountAuth.forgorPassword(emailAddress, appId).then((response) => {
                setIsLoading(false);
                if (response.isSuccess) {
                    toast.success(response.message, { position: "top-right", })
                    setMessage(`${response.message}. Please check your E-mails`)
                    setEmailAddress('')
                    setIsSuccess(true);
                    onClose();
                } else {
                    toast.error(response.message, { position: "top-right", })
                    setMessage(response.message);
                    setIsSuccess(false);
                }
            })
            //onClose();
        } catch (error) {
            setMessage(error);
        }

    };

    return (
        <>
            <Dialog open={open} fullWidth>
                <DialogTitle textAlign="center">Forgot Password</DialogTitle>
                <DialogContent>
                    <form onSubmit={(e) => e.preventDefault()}>
                        Lost your password? Please enter your email address. You will receive a link to create a new password via email.
                        <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem', paddingTop: '20px' }} >
                            <TextField fullWidth label="Email Address" type={'email'} value={emailAddress} onChange={e => setEmailAddress(e.target.value)} />
                            {!isSuccess && message && (<Alert variant="outlined" severity="error"> {message} </Alert>)}
                        </Stack>
                    </form>
                </DialogContent>
                <DialogActions sx={{ p: '1.25rem' }}>
                    <Button onClick={onClose}>Cancel</Button>
                    <Button color="primary" onClick={handleSubmit} variant="contained" disabled={isLoading || !isFormValid()}>
                        Send reset link
                    </Button>
                </DialogActions>
            </Dialog>
        </>

    );
};


export default SideLogin