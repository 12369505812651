import { Box, Grid } from '@mui/material'
import React from 'react'
import FlexBetween from '../../../../components/FlexBetween'
import Header from '../../../../components/Table/Header'
import ActivityPage from './ActivityPage'
import InterventionActivityPage from './InterventionActivityPage'

const Activities = () => {
    return (
        <Box m="0.5rem 0.5rem">
            <FlexBetween>
                <Header title='Activities' />
            </FlexBetween>
            <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>

                <Grid item xs={12} sm={12} md={6}>
                    <ActivityPage />
                </Grid>
                <Grid item xs={12} sm={12} md={6}>
                    <InterventionActivityPage />
                </Grid>

            </Grid>
        </Box>
    )
}

export default Activities