export default class Auth {
    static STORAGE_KEY = 'watchmenToken';
    static PERMISSIONS_KEY = 'watchmenPermissions';
    static LOCATIONS_KEY = 'watchmenLocations';
    static USER_INFO = 'watchmenUserInfo';

    static getUserInfo() {
        return window.sessionStorage.getItem(Auth.USER_INFO);
    }

    static setUserInfo(userInfo) {
        window.sessionStorage.setItem(Auth.USER_INFO, JSON.stringify(userInfo));
    }

    static removeUserInfo() {
        window.sessionStorage.removeItem(Auth.USER_INFO);
    }

    static setLocations(locations) {
        window.sessionStorage.setItem(Auth.LOCATIONS_KEY, JSON.stringify(locations));
    }


    static getUserLocations() {
        return window.sessionStorage.getItem(Auth.LOCATIONS_KEY) !== 'undefined' ? JSON.parse(window.sessionStorage.getItem(Auth.LOCATIONS_KEY)) : [];
    }

    static removeLocations() {
        window.sessionStorage.removeItem(Auth.LOCATIONS_KEY);
    }

    static getToken() {
        const userInfo = JSON.parse(this.getUserInfo());
        return userInfo?.token;
    }
}