import React from 'react';
import { AppBar, Typography, IconButton, Toolbar, Box, } from "@mui/material";
import FlexBetween from '../../FlexBetween';
import { MenuUnfoldOutlined } from '@ant-design/icons';
import { MenuFoldOutlined } from '@ant-design/icons/lib/icons';
import HeaderContent from './HeaderContent';
import useAuth from '../../../hooks/useAuth';
import { Link } from 'react-router-dom';
import LogoImages from '../../../assests/logo/logo512-removebg-preview.png';

const Header = ({ isSidebarOpen, setIsSidebarOpen }) => {
    const { auth } = useAuth();

    return (
        <AppBar color="inherit" sx={{ position: "fixed", boxShadow: "3", }}>
            <Toolbar sx={{ justifyContent: "space-between" }}>

                {/* LEFT SIDE */}
                <FlexBetween >
                    <Link to={"/dashboard"} className="removeLink" >
                        <IconButton size="small" color="inherit">
                            <img src={LogoImages} alt="RaiseMyHand" width="55" />
                            <Typography variant='subtitle1' sx={{ fontStyle: 'italic', fontWeight: 'bold', fontVariant: 'inherit' }}>RaiseMyhand</Typography>
                        </IconButton>
                    </Link>

                    {auth?.username && (
                        <Box m="0.5rem 4rem">
                            <IconButton color="inherit" onClick={() => setIsSidebarOpen(!isSidebarOpen)}>
                                {!isSidebarOpen ? <MenuUnfoldOutlined /> : <MenuFoldOutlined />}
                            </IconButton>
                        </Box>
                    )}
                    
                </FlexBetween>

                <FlexBetween gap="0.5rem">

                </FlexBetween>

                {/* RIGHT SIDE */}
                <FlexBetween>
                    <HeaderContent />
                </FlexBetween>

            </Toolbar>
        </AppBar>
    )
}

export default Header