import { Grid, ImageList, ImageListItem, Typography } from '@mui/material'
import React from 'react'
import AgapeYouthMovement from '../../assests/sponsors/Agape-Youth-Movement.png'
import Ambassadors4Change from '../../assests/sponsors/Ambassadors4Change.jpg'
import StudyOfViolenceAndReconciliation from '../../assests/sponsors/The Centre for the Study of Violence and Reconciliation.png'
import DiakoniaCouncilofChurches from '../../assests/sponsors/diakoniaCouncilofChurches.png'
import ImbelekoFoundation from '../../assests/sponsors/ImbelekoFoundation.jpg'
import IncemaNPO from '../../assests/sponsors/IncemaNPO.png'
import IndlezanaYezwe from '../../assests/sponsors/IndlezanaYezwe.jpg'
import SETSHABELOFAMILYANDCHILDSERVICES from '../../assests/sponsors/SETSHABELOFAMILYANDCHILDSERVICES.png'
import SharedValueAfricaInitiative from '../../assests/sponsors/SharedValueAfricaInitiative.jpg'

const Sponsors = () => {
    const sponsors = [
        {
            id: 1,
            img: AgapeYouthMovement,
            title: 'Agape Youth Movement'
        },
        {
            id: 2,
            img: Ambassadors4Change,
            title: 'Ambassadors for Change',
        },
        {
            id: 3,
            img: StudyOfViolenceAndReconciliation,
            title: 'The Centre for the Study of Violence and Reconciliation',
        },
        {
            id: 4,
            img: DiakoniaCouncilofChurches,
            title: 'Diakonia Council of Churches'
        },
        {
            id: 5,
            img: ImbelekoFoundation,
            title: 'Imbeleko Foundation'
        },
        {
            id: 6,
            img: IncemaNPO,
            title: 'Incema NPO'
        },
        {
            id: 7,
            img: IndlezanaYezwe,
            title: 'Indlezana Yezwe'
        },
        {
            id: 8,
            img: SETSHABELOFAMILYANDCHILDSERVICES,
            title: 'Setshabel of family and child service',
        },
        {
            id: 9,
            img: StudyOfViolenceAndReconciliation,
            title: 'The Centre for the Study of Violence and Reconciliation',
            cols: 2 // 1 rows 2 columns
        },
        {
            id: 10,
            img: SharedValueAfricaInitiative,
            title: 'Shared Value Africa Initiative'
        }
    ]

    return (
        <Grid>
            <Typography variant="h4" align="center" gutterBottom>Reporting Organizations </Typography>
            <ImageList sx={{
                gridAutoFlow: "column",
                gridTemplateColumns: "repeat(auto-fit, minmax(160px,1fr)) !important",
                gridAutoColumns: "minmax(80px, 1fr)"
            }}>
                {sponsors.map((image) => (
                    <div className="moving-list-container" key={image.id}> {/* Add the "moving-list-container" class */}
                        <ImageListItem>
                            <img src={image.img} alt={image.title} style={{ width: '85px', height: '85px' }}/>
                            {/* <ImageListItemBar title={image.title} /> */}
                        </ImageListItem>
                    </div>
                ))}

            </ImageList>
        </Grid>
    )
}

export default Sponsors