const GoogleMapAPIKey = "AIzaSyAMkLaiIJKOE_gCmOBlyYf90xNHAbAklRM";

const AppColorTheme = {
    color: "#1976D2",
    textColor: "#ffffff",
    SAGold: "#FFB612",
    SAGreen: "#007A4D",
    SARed: "#DE3831",
    SABlue: "#002395",
    SABlack: "#000000",
    SAWhite: "#FFFFFF"
}

const Roles = {
    SuperAdmin: "SuperAdmin",
    Admin: "Admin",
    General: "General",
    ManagementReport: "Management and Report tracking",
    LoadAmendIntervention: "Load and Amend Intervention Plans"
};

const App = {
    NSPMonitoring: 2
};

const Platform = {
    NSPMonitor: 1,
    HIVandAIDS: 2,
    BeyondPresidentialSummitIITRacker: 3
};

const FullNameRoles = [
    {
        id: 1,
        name: "SuperAdmin",
        description: "Super Administrator"
    }, {
        id: 2,
        name: "Admin",
        description: "Administrator"
    }, {
        id: 3,
        name: "General",
        description: "General User"
    }, {
        id: 4,
        name: "Management and Report tracking",
        description: "Roles for NSP"
    }, {
        id: 5,
        name: "Load and Amend Intervention Plans",
        description: "Roles for NSP"
    },
];


export const AppSettings = {
    AppColorTheme,
    FullNameRoles,
    Roles,
    GoogleMapAPIKey,
    App,
    Platform
}