import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button, Dialog, DialogActions, DialogContent, IconButton, Stack } from '@mui/material';
import { MenuItem, Select, Tooltip, InputLabel, FormControl, DialogTitle } from "@mui/material";
import { Delete } from '@mui/icons-material';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import Header from '../../../../components/Table/Header';


const BPPillarInterventionPage = () => {
    const [createModalOpen, setCreateModalOpen] = useState(false);
    const [isError, setIsError] = useState(false);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const confirm = useConfirm();
    const [refresh, setRefresh] = useState(false);
    let platformId = 3;

    const handleCancelRowEdits = () => {
        setValidationErrors({});
    };

    const handleDeleteRow = useCallback((row) => {
        confirm({
            title: `Are you sure you want to delete?`, cancellationText: `No`, confirmationText: `Yes`,
            description: `This will permanently delete: ${row.original.pillarName} & ${row.original.interventionName}.`
        }).then(async () => {
            setIsLoading(true);
            const resp = await NSPMonitorAuth.DeletePillarInterventionById(row.original.id);
            setIsLoading(false);
            if (resp.content === "") {
                toast.error("Error occured while attemping to Delele.Please Contact Admininstrator", { position: "top-right", })
            }
            if (resp.content?.isSuccess) {
                setRefresh(!refresh);
                toast.success(resp.content.message, { position: "top-right", });
            } else {
                toast.error(resp.content.message, { position: "top-right", })
            }
        }).catch(error => {
            console.log("Deletion cancelled." + error)
            toast.error(error, { position: "top-right", })
        });
        tableData.splice(row.index, 1);
        setTableData([...tableData]);
    }, [confirm, refresh, tableData]);



    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = true;
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({ ...validationErrors });
                    }
                },
            };
        },
        [validationErrors],
    );

    const GetPillarInterventions = async () => {
        if (!tableData.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }

        try {
            const res = await NSPMonitorAuth.GetAllPillarInterventions(null, platformId, pagination.pageIndex, pagination.pageSize);
            setTableData(res.content.items);
            setRowCount(res.content.totalItems)
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        } finally {
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        }
    }


    useEffect(() => {
        GetPillarInterventions();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData.length, pagination.pageIndex, pagination.pageSize, refresh])

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 10,
            },
            {
                accessorKey: 'pillarName',
                header: 'Pillar',
                enableEditing: false,
                size: 240,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),

            },
            {
                accessorKey: 'interventionName',
                header: 'Intervention',
                enableEditing: false,
                size: 700,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
        ],
        [getCommonEditTextFieldProps],
    );


    return (
        <>
            <MaterialReactTable displayColumnDefOptions={{ 'mrt-row-actions': { muiTableHeadCellProps: { align: 'center', }, size: 120, }, }}
                columns={columns}
                muiTable
                data={tableData}
                editingMode="modal" //default
                enableColumnOrdering
                enablePagination
                rowCount={rowCount}
                enableEditing
                manualPagination
                muiTableBodyCellEditTextFieldProps={{ multiline: true }}
                onPaginationChange={setPagination}
                onEditingRowCancel={handleCancelRowEdits}
                positionGlobalFilter="left"
                state={{
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                }}
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement='right' title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <>
                        <Button
                            color="primary"
                            onClick={() => setCreateModalOpen(true)}
                            variant="contained" >
                            Add Pillar-Intervention
                        </Button>
                        <Header subtitle="Pillar - Intervention" />
                    </>
                )} />

            <CreateNewAccountModal
                open={createModalOpen}
                onClose={() => setCreateModalOpen(false)}
                refresh={refresh}
                platformId={platformId}
                setRefresh={() => setRefresh(!refresh)} />
        </>
    )
}

export const CreateNewAccountModal = ({ open, onClose, refresh, platformId, setRefresh }) => {
    const [pillarId, setPillarId] = useState(0);
    const [interventionId, setInterventionId] = useState(0);
    const [pillars, setPillars] = useState([]);
    const [interventions, setInterventions] = useState([]);
    const [isLoading, setIsLoading] = useState(false);

    const isFormValid = () => {
        let valid = true;
        if (pillarId === '' || pillarId < 1) {
            valid = false;
        }
        if (interventionId === '' || interventionId < 1) {
            valid = false;
        }
        return valid;
    }
    const GetAllInterventions = async () => {
        const res = await NSPMonitorAuth.GetAllIntervention(null, platformId, 0, 100);
        setInterventions(res.content.items);
    }

    const GetAllPillars = async () => {
        const res = await NSPMonitorAuth.GetAllPillars(1);
        setPillars(res.content)
    }


    useEffect(() => {
        GetAllInterventions();
        GetAllPillars();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [platformId])


    const handleSubmit = async () => {
        try {
            setIsLoading(true);
            const data = { "id": 0, "pillarId": pillarId, "interventionId": interventionId, "platformId": platformId };
            const resp = await NSPMonitorAuth.UpsertPillarInterventions(data);
            setIsLoading(false);
            if (resp.content === "") {
                toast.error("Error occured while attemping to Delele.Please Contact Admininstrator", { position: "top-right", })
            }
            if (resp.content?.isSuccess) {
                toast.success(resp.content.message, { position: "top-right", })
                onClose();
                setRefresh(!refresh);
                setPillarId(0)
                setInterventionId(0)
            } else {
                toast.error(resp.content.message, { position: "top-right", })
            }
        } catch (error) {
            setIsLoading(false);
            toast.error(error, { position: "top-right", })
        }
    };

    return (
        <Dialog open={open} fullWidth maxWidth={'xl'}>
            <DialogTitle textAlign="center">Create New Pillar-Intervention</DialogTitle>
            <DialogContent>
                <form onSubmit={(e) => e.preventDefault()}>

                    <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem', paddingTop: '10px' }}>
                        <FormControl variant="standard">
                            <InputLabel>Select Pillar</InputLabel>
                            <Select defaultValue={0} label="Pillar" value={pillarId} onChange={(e) => { setPillarId(e.target.value) }}>
                                <MenuItem value={0}><em>None</em></MenuItem>
                                {pillars.map(pillar => { return (<MenuItem key={pillar.id} value={pillar.id}> {pillar.name} </MenuItem>) })}
                            </Select>
                        </FormControl>

                        <FormControl variant="standard">
                            <InputLabel>Select Intervention</InputLabel>
                            <Select defaultValue={0} label="Intervention" value={interventionId} onChange={(e) => { setInterventionId(e.target.value) }}>
                                <MenuItem value={0} ><em>None</em></MenuItem>
                                {interventions.map(intervention => {
                                    return (<MenuItem key={intervention.id.toString()} value={intervention.id}> {intervention.name} </MenuItem>)
                                })}
                            </Select>
                        </FormControl>
                    </Stack>
                </form>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button onClick={onClose}>Cancel</Button>
                <Button color="primary" onClick={handleSubmit} variant="contained" disabled={isLoading || !isFormValid()}>Add</Button>
            </DialogActions>
        </Dialog>
    )
}

export default BPPillarInterventionPage