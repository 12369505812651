import React, { useMemo, useEffect, useState, useRef } from 'react';
import { FormControl, TextField, Autocomplete, Typography, Grid } from '@mui/material';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import parse from 'autosuggest-highlight/parse';
import { debounce } from '@mui/material/utils';
import { AppSettings } from '../../AppSettings';

const COUNTRY_CODE = 'ZA';

function loadScript(src, position, id) {
    if (!position) {
        return;
    }
    const script = document.createElement('script');
    script.setAttribute('async', '');
    script.setAttribute('id', id);
    script.src = src;
    position.appendChild(script);
}

const autocompleteService = { current: null };



const Address = ({ label, onChange }) => {
    const [value, setValue] = useState(null);
    const [inputValue, setInputValue] = useState('');
    const [options, setOptions] = useState([]);
    const loaded = useRef(false);

    if (typeof window !== 'undefined' && !loaded.current) {
        if (!document.querySelector('#google-maps')) {
            loadScript(`https://maps.googleapis.com/maps/api/js?key=${AppSettings.GoogleMapAPIKey}&libraries=places`,
                document.querySelector('head'), 'google-maps'
            );
        }
        loaded.current = true;
    }

    const fetch = useMemo(
        () => debounce((request, callback) => {
            autocompleteService.current.getPlacePredictions({ ...request, componentRestrictions: { country: COUNTRY_CODE } }, callback);
        }, 200),
        []
    );


    useEffect(() => {
        let active = true;
        if (!autocompleteService.current && window.google) {
            autocompleteService.current = new window.google.maps.places.AutocompleteService();
        }
        if (!autocompleteService.current) {
            // Wait for window.google to become available
            const timer = setInterval(() => {
                if (window.google) {
                    autocompleteService.current = new window.google.maps.places.AutocompleteService();
                    clearInterval(timer); // Stop waiting once it's available
                }
            }, 100);
        }
        if (!autocompleteService.current) {
            return undefined;
        }
        if (inputValue === '') {
            setOptions(value ? [value] : []);
            return undefined;
        }
        fetch({ input: inputValue }, (results) => {
            if (active) {
                const newOptions = value ? [value, ...(results || [])] : results || [];
                setOptions(newOptions);
            }
        });
    
        return () => { active = false; };
    }, [value, inputValue, fetch]);

    
    return (
        <FormControl fullWidth>
            <Autocomplete
                getOptionLabel={(option) => typeof option === 'string' ? option : option.description }
                filterOptions={(x) => x}
                options={options}
                autoComplete
                includeInputInList
                filterSelectedOptions
                value={value}
                noOptionsText="No locations"
                onChange={(event, newValue) => {
                    setOptions(newValue ? [newValue, ...options] : options);
                    setValue(newValue);
                    onChange(newValue);
                }}
                onInputChange={(event, newInputValue) => {
                    setInputValue(newInputValue);
                }}
                renderInput={(params) => <TextField {...params} label={label} fullWidth sx={{ width: '100%' }} />}
                renderOption={(props, option) => {
                    const matches = option.structured_formatting.main_text_matched_substrings || [];

                    const parts = parse(
                        option.structured_formatting.main_text,
                        matches.map((match) => [match.offset, match.offset + match.length])
                    );

                    return (
                        <li {...props}>
                            <Grid container alignItems="center">
                                <Grid item sx={{ display: 'flex', width: 44 }}>
                                    <LocationOnIcon sx={{ color: 'text.secondary' }} />
                                </Grid>
                                <Grid item sx={{ width: 'calc(100% - 44px)', wordWrap: 'break-word' }} >
                                    {parts.map((part, index) => (
                                        <Typography key={index}
                                            component="span"
                                            sx={{ fontWeight: part.highlight ? 'bold' : 'regular' }} >
                                            {part.text}
                                        </Typography>
                                    ))}
                                    <Typography variant="body2" color="text.secondary">
                                        {option.structured_formatting.secondary_text}
                                    </Typography>
                                </Grid>
                            </Grid>
                        </li>
                    );
                }}
            />
        </FormControl>
    )
}

export default Address