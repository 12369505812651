export default class AuthUser {
    static STORAGE_KEY = 'watchmenToken';
    static USER_KEY = 'watchmenUser';
    static PERMISSIONS_KEY = 'watchmenPermissions';
    static LOCATIONS_KEY = 'watchmenLocations';
    static USER_ROLES = 'watchmenRoles';
    static USER_INFO = 'watchmenUserInfo';

    static getUserInfo() {
        return window.sessionStorage.getItem(AuthUser.USER_INFO);
    }

    static setUserInfo(userInfo) {
        window.sessionStorage.setItem(AuthUser.USER_INFO, JSON.stringify(userInfo));
    }

    static removeUserInfo() {
        window.sessionStorage.removeItem(AuthUser.USER_INFO);
    }

    static getToken() {
        return window.sessionStorage.getItem(AuthUser.STORAGE_KEY);
    }

    static setToken(token) {
        window.sessionStorage.setItem(AuthUser.STORAGE_KEY, token);
    }

    static removeToken() {
        window.sessionStorage.removeItem(AuthUser.STORAGE_KEY);
    }

    static getUser() {
        return window.sessionStorage.getItem(AuthUser.USER_KEY);
    }

    static setUser(token) {
        window.sessionStorage.setItem(AuthUser.USER_KEY, token);
    }

    static removeUser() {
        window.sessionStorage.removeItem(AuthUser.USER_KEY);
    }

    static getRoles() {
        return window.sessionStorage.getItem(AuthUser.USER_ROLES);
    }

    static setRoles(roles) {
        window.sessionStorage.setItem(AuthUser.USER_ROLES, JSON.stringify(roles));
    }

    static removeRoles() {
        window.sessionStorage.removeItem(AuthUser.USER_ROLES);
    }

    static setUserPermissions(permissions) {
        window.sessionStorage.setItem(AuthUser.PERMISSIONS_KEY, JSON.stringify(permissions));
    }

    static setLocations(locations) {
        window.sessionStorage.setItem(AuthUser.LOCATIONS_KEY, JSON.stringify(locations));
    }

    static getUserPermissions() {
        return window.sessionStorage.getItem(AuthUser.PERMISSIONS_KEY) !== 'undefined' ? JSON.parse(window.sessionStorage.getItem(AuthUser.PERMISSIONS_KEY)) : [];
    }

    static getUserLocations() {
        return window.sessionStorage.getItem(AuthUser.LOCATIONS_KEY) !== 'undefined' ? JSON.parse(window.sessionStorage.getItem(AuthUser.LOCATIONS_KEY)) : [];
    }

    static removeUserPermissions() {
        window.sessionStorage.removeItem(AuthUser.PERMISSIONS_KEY);
    }

    static removeLocations() {
        window.sessionStorage.removeItem(AuthUser.LOCATIONS_KEY);
    }
}