import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Tooltip, IconButton, Button, Grid, Dialog, DialogTitle, DialogContent, DialogActions, Stack } from '@mui/material';
import { Delete, Edit, Attachment, GetApp } from '@mui/icons-material';
import { useNavigate } from 'react-router-dom';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import Header from '../../../../components/Table/Header';
import CommonAuth from '../../../../state/APIConnect/Common';
import { toast } from 'react-toastify';


const QuarterlyAchievement = () => {
    const [isError, setIsError] = useState(false);
    const [quartelyId, setQuartelyId] = useState(0);
    const [isLoading, setIsLoading] = useState(false);
    const [isRefetching, setIsRefetching] = useState(false);
    const [tableData, setTableData] = useState([]);
    const [validationErrors, setValidationErrors] = useState({});
    const [rowCount, setRowCount] = useState(0);
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
    const [openDownloadDailog, setOpenDownloadDailog] = useState(false);
    const navigate = useNavigate();


    const handleAddRow = () => {
        navigate("/quarterlyAchievement/create");
    }

    const handleEditRow = (id) => {
        navigate("/quarterlyAchievement/edit/" + id);
    }

    const handleDeleteRow = (id) => {
        navigate("" + id);
    }

    const downloadDailog = (id) => {
        setQuartelyId(id);
        setOpenDownloadDailog(true);
    }

    const GetAllQuarterlyAchievementsAsync = async () => {
        if (!tableData.length) {
            setIsLoading(true);
        } else {
            setIsRefetching(true);
        }
        try {
            const res = await NSPMonitorAuth.GetAllQuarterlyAchievements(null, pagination.pageIndex, pagination.pageSize);
            setTableData(res.content.items);
            setRowCount(res.content.totalItems)
        } catch (error) {
            setIsError(true);
            console.error(error);
            return;
        } finally {
            setIsError(false);
            setIsLoading(false);
            setIsRefetching(false);
        }
    }

    useEffect(() => {
        GetAllQuarterlyAchievementsAsync();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData.length, pagination.pageIndex, pagination.pageSize])

    const getCommonEditTextFieldProps = useCallback(
        (cell) => {
            return {
                error: !!validationErrors[cell.id],
                helperText: validationErrors[cell.id],
                onBlur: (event) => {
                    const isValid = true;
                    if (!isValid) {
                        //set validation error for cell if invalid
                        setValidationErrors({
                            ...validationErrors,
                            [cell.id]: `${cell.column.columnDef.header} is required`,
                        });
                    } else {
                        //remove validation error for cell if valid
                        delete validationErrors[cell.id];
                        setValidationErrors({
                            ...validationErrors,
                        });
                    }
                },
            };
        },
        [validationErrors],
    );

    const columns = useMemo(
        () => [
            {
                accessorKey: 'id',
                header: 'ID',
                enableColumnOrdering: false,
                enableEditing: false, //disable editing on this column
                enableSorting: false,
                size: 10,
            },
            {
                accessorKey: 'pillarName',
                header: 'Pillar',
                size: 150,
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'interventionName',
                header: 'Intervention',
                size: 250,
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'activityName',
                header: 'Activity',
                size: 350,
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'progressNative',
                header: 'Progress Native',
                size: 450,
                enableEditing: false,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'correctiveMeasures',
                header: 'Corrective Measures',
                size: 300,
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },
            {
                accessorKey: 'dateCreated',
                header: 'Date Created',
                size: 20,
                Cell: ({ cell }) => {
                    const date = new Date(cell.getValue());
                    return date.toDateString();
                },
                muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
                    ...getCommonEditTextFieldProps(cell),
                }),
            },

        ],
        [getCommonEditTextFieldProps],
    );


    return (
        <Box>
            <MaterialReactTable displayColumnDefOptions={{ 'mrt-row-actions': { muiTableHeadCellProps: { align: 'center', }, size: 120, }, }}
                columns={columns}
                muiTable
                data={tableData}
                initialState={{ columnVisibility: { id: false } }} //hide firstName column by default
                enableColumnOrdering
                enableEditing
                enablePagination
                rowCount={rowCount}
                manualPagination
                onPaginationChange={setPagination}
                muiTableBodyCellEditTextFieldProps={{ multiline: true }}
                // onEditingRowSave={handleSaveRowEdits}
                // onEditingRowCancel={handleCancelRowEdits}
                state={{
                    isLoading,
                    pagination,
                    showAlertBanner: isError,
                    showProgressBars: isRefetching,
                }}
                positionGlobalFilter="left"
                renderRowActions={({ row, table }) => (
                    <Box sx={{ display: 'flex', gap: '1rem' }}>
                        <Tooltip arrow placement="left" title="Edit">
                            <IconButton onClick={() => handleEditRow(row.original.id)}>
                                <Edit />
                            </IconButton>
                        </Tooltip>
                        <Tooltip arrow placement="right" title="Delete">
                            <IconButton color="error" onClick={() => handleDeleteRow(row.original.id)} disabled>
                                <Delete />
                            </IconButton>
                        </Tooltip>
                        {row.original.fileExists === true ? (
                            <Tooltip arrow placement="right" title="View Attachment">
                                <IconButton color="error" onClick={() => downloadDailog(row.original.id)}>
                                    <Attachment />
                                </IconButton>
                            </Tooltip>
                        ) : <Tooltip arrow placement="right" title="View Attachment">
                            <IconButton color="error" disabled>
                                <Attachment />
                            </IconButton>
                        </Tooltip>
                        }
                    </Box>
                )}
                renderTopToolbarCustomActions={() => (
                    <>
                        <Button
                            color="primary"
                            onClick={() => handleAddRow()}
                            variant="contained" >
                            Add Quarterly Achievement
                        </Button>
                        <Header subtitle="Achievement" />
                    </>
                )} />

            <CreateNewAccountModal
                columns={columns}
                open={openDownloadDailog}
                quartelyId={quartelyId}
                onClose={() => setOpenDownloadDailog(false)} />

        </Box>
    )
}

export const CreateNewAccountModal = ({ open, onClose, quartelyId }) => {
    const [achievementsFiles, setAchievementsFiles] = useState([]);

    const getFiles = async () => {
        if (quartelyId > 0) {
            const res = await NSPMonitorAuth.GetQaurterlyReportByAchievementById(quartelyId);
            let data = res.content;
            setAchievementsFiles(data.achievementsFiles);
        }
    }

    const handleDownload = async (fileName) => {
        try {
            await CommonAuth.DownloadFileByFileName(fileName)
        } catch (error) {
            toast.error(error, { position: "top-right", })
        }
    };

    useEffect(() => {
        getFiles();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [quartelyId]);


    return (
        <Dialog open={open} fullWidth>
            <DialogTitle textAlign="center">Download Documents</DialogTitle>
            <DialogContent>
                <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem', paddingTop: '10px' }}>
                    {achievementsFiles.map(achievementsFile => (
                        <Grid item xs={12} key={achievementsFile.id}>
                            <Button variant="outlined" startIcon={<GetApp />} onClick={() => handleDownload(achievementsFile.fileId)}>
                                {achievementsFile.fileId}
                            </Button>
                        </Grid>
                    ))}
                </Stack>
            </DialogContent>
            <DialogActions sx={{ p: '1.25rem' }}>
                <Button color="primary" onClick={onClose} variant="contained" >Cancel</Button>
            </DialogActions>
        </Dialog>
    );
};

export default QuarterlyAchievement