import React from 'react'
import { Box, useMediaQuery } from '@mui/material';
import Profile from './Profile';
import FlexBetween from '../../../FlexBetween';
import MobileSection from './MobileSection';


const HeaderContent = () => {
    const matchesXs = useMediaQuery((theme) => theme.breakpoints.down('md'));
    return (
        <FlexBetween >
            <Box sx={{ width: '100%', ml: { xs: 0, md: 1 } }}>
                {matchesXs && <Box sx={{ width: '100%', ml: 1 }} />}
            </Box>
            {!matchesXs && <Profile />}
            {matchesXs && <MobileSection />}
        </FlexBetween>
    )
}

export default HeaderContent