import React, { useEffect, useState } from "react";
import { Box, ClickAwayListener, Drawer, List, ListItemButton } from "@mui/material";
import { ListItemIcon, ListItemText, Typography, useTheme, ListItem } from "@mui/material";
import { ChevronRightOutlined } from "@mui/icons-material";
import { useLocation, useNavigate } from "react-router-dom";
import navItems from "../../../NavItems";
import { AppSettings } from "../../../AppSettings";

const MainDrawer = ({ user, drawerWidth, isSidebarOpen, setIsSidebarOpen, isNonMobile }) => {
    const { pathname } = useLocation();
    const [active, setActive] = useState("");
    const navigate = useNavigate();
    const theme = useTheme();

    const authenticatedRoleName = user && user.roles ? user.roles.map(role => role.roleName) : [];
    const matchingItems = navItems.filter(item => {
        return item.roles?.[0] === null || item.roles?.some(role => authenticatedRoleName.includes(role));
    });


    useEffect(() => {
        setActive(pathname.substring(1));
    }, [pathname]);

    return (
        <Box component="nav" >
            {isSidebarOpen && (
                <ClickAwayListener onClickAway={() => { setIsSidebarOpen(false) }}>
                    <Drawer
                        open={isSidebarOpen}
                        onClose={() => setIsSidebarOpen(false)}
                        variant="persistent"
                        anchor="left"
                        sx={{
                            width: drawerWidth,
                            "& .MuiDrawer-paper": {
                                color: AppSettings.AppColorTheme.textColor,
                                backgroundColor: AppSettings.AppColorTheme.color,
                                boxSixing: "border-box",
                                borderWidth: isNonMobile ? 0 : "2px",
                                width: drawerWidth,
                                [theme.breakpoints.up('md')]: {
                                    top: '66px'
                                }
                            },
                        }}>

                        <Box width="100%">
                            <List>
                                {matchingItems.map(({ link, text, icon }) => {
                                    if (!icon) {
                                        return (<Typography key={text} sx={{ m: "2.25rem 0 1rem 3rem" }}> {text} </Typography>);
                                    }
                                    const lcText = link.toLowerCase();

                                    return (
                                        <ListItem key={text} disablePadding>
                                            <ListItemButton
                                                onClick={() => {
                                                    navigate(`/${lcText}`);
                                                    setActive(lcText);
                                                    setIsSidebarOpen(false);
                                                }}
                                                sx={{
                                                    backgroundColor: active === lcText ? theme.palette.secondary[300] : "transparent",
                                                    color: active === lcText ? theme.palette.primary[600] : theme.palette.secondary[100],
                                                }}>
                                                <ListItemIcon
                                                    sx={{
                                                        ml: "2rem",
                                                        color: active === lcText ? theme.palette.primary[600] : theme.palette.secondary[200],
                                                    }}>
                                                    {icon}
                                                </ListItemIcon>
                                                <ListItemText primary={text} />
                                                {active === lcText && (<ChevronRightOutlined sx={{ ml: "auto" }} />)}
                                            </ListItemButton>
                                        </ListItem>
                                    );
                                })}
                            </List>
                        </Box>
                    </Drawer>
                </ClickAwayListener>
            )}
        </Box >
    )
}
export default MainDrawer