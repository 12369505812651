import * as React from 'react';
import PropTypes from 'prop-types';
import Box from '@mui/material/Box';
import Container from '@mui/material/Container';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';



function Copyright() {
    return (
        <Typography variant="body2" color="text.secondary" align="center">
            {'Copyright © '}
            {/* <Link color="inherit" href="https://mui.com/" target="_blank">
                Your Website
            </Link>{' '} */}
            {new Date().getFullYear()}
            {'.'}
        </Typography>
    );
}

const Footer = (props) => {
    const { description, title } = props;
    return (
        <Box component="footer" sx={{
            py: 3,
            px: 2,
            mt: 'auto',
            backgroundColor: (theme) =>
                theme.palette.mode === 'light'
                    ? theme.palette.grey[200]
                    : theme.palette.grey[800],
        }}>
            <Container maxWidth="lg">
                <Typography variant="h6" align="center" gutterBottom>
                    {title} & <Link color="inherit" href="https://mdi-za.org/" target="_blank"> mdi-za.org</Link>{' '}
                </Typography>
                <Typography variant="subtitle1"
                    align="center"
                    color="text.secondary"
                    component="p">
                    {description}
                </Typography>
                <Copyright />
            </Container>
        </Box>
    )
}
Footer.propTypes = {
    description: PropTypes.string.isRequired,
    title: PropTypes.string.isRequired,
};
export default Footer