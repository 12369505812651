import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import Avatar from '@mui/material/Avatar';
import Button from '@mui/material/Button';
import CssBaseline from '@mui/material/CssBaseline';
import TextField from '@mui/material/TextField';
import HowToRegIcon from '@mui/icons-material/HowToReg';
import { Grid, Box, Paper, Typography, DialogActions } from '@mui/material';
import { Dialog, DialogTitle, DialogContentText, DialogContent } from '@mui/material';
import { MenuItem, Select, FormControl, InputLabel } from '@mui/material';
import NSPMonitorAuth from '../../state/APIConnect/RaiseMyhand/NSPMonitor';
import AccountAuth from '../../state/APIConnect/Account';
import { toast } from 'react-toastify';
import validator from 'validator';
import RaiseMyHandImageofWoman from '../../assests/users/RaiseMyHand.jpeg'
import { AppSettings } from '../../AppSettings';

const SideSignUp = () => {
    const [institutionId, setInstitutionId] = useState(0);
    const [firstName, setFirstName] = useState("");
    const [lastName, setLastName] = useState("");
    const [mobileNumber, setMobileNumber] = useState("");
    const [emailAddress, setEmailAddress] = useState("");
    const [password, setPassword] = useState("");
    const [institutions, setInstitutions] = useState([]);
    const [confirmPassword, setConfirmPassword] = useState("");
    const [validationError, setValidationError] = useState("");
    const [pagination] = useState({ pageIndex: 0, pageSize: 100 });
    const [isLoading, setIsLoading] = useState(false);
    const [open, setOpen] = useState(false);
    const navigate = useNavigate();

    const handleClose = () => {
        setInstitutionId(0);
        setFirstName("");
        setLastName("");
        setMobileNumber("");
        setEmailAddress("")
        setOpen(false);
    };

    const isFormValid = () => {
        let valid = true;
        if (firstName === '' || lastName === '' || mobileNumber === '' || emailAddress === ''
            || password === '' || confirmPassword === '') {
            valid = false;
        }
        if (password !== confirmPassword) {
            valid = false;
        }
        if (institutionId <= 0) {
            valid = false;
        }
        return valid;
    }

    const AddInstitution = () => {
        navigate("/registerInstitution");
    }

    const SignInPage = () => {
        navigate("/login");
    }

    const IsvalidEmail = () => {
        if (validator.isEmail(emailAddress)) {
            return true;
        }
        setValidationError('Invalid Email Address')
        return false;
    }

    useEffect(() => {
        const GetAllInstitutions = async () => {
            const res = await NSPMonitorAuth.GetAllInstitutions(null, pagination.pageIndex, pagination.pageSize);
            setInstitutions(res.content.items);
        }
        GetAllInstitutions();

    }, [pagination.pageIndex, pagination.pageSize])


    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            let bodyParameters = {
                "firstName": firstName, "surname": lastName, "mobileNumber": mobileNumber, 
                "emailAddress": emailAddress, "password": password, "appId": 2, "companyId": 0,
                "institutionId": institutionId, "categoryId": 0, "confirmPasword": confirmPassword,
                "userRoles": AppSettings.Roles.General
            }
            setIsLoading(true);
            await AccountAuth.signUp(bodyParameters).then((response) => {
                setIsLoading(false);
                if (response.content === null) {
                    toast.error(response.error_content.message, { position: "top-right", })
                }
                else {
                    setOpen(true);
                    toast.success(response.content.message, { position: "top-right", });
                }
                setPassword("");
                setConfirmPassword("");
            })
        } catch (error) {
            toast.error(error.message, { position: "top-right", })
        }

    };


    return (
        <Grid container component="main" sx={{ height: '100vh' }}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7} sx={{
                // backgroundImage: 'url(https://source.unsplash.com/random)',
                backgroundImage: `url(${RaiseMyHandImageofWoman})`, // Use the imported image
                backgroundRepeat: 'no-repeat',
                backgroundColor: (t) =>
                    t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                backgroundSize: 'cover',
                backgroundPosition: 'center',
            }} />

            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square>
                <Box sx={{ my: 4, mx: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <HowToRegIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5">
                        Sign Up
                    </Typography>
                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }} >
                        <Grid container spacing={2}>

                            <Grid item xs={12}>
                                <FormControl fullWidth>
                                    <InputLabel>Institution</InputLabel>
                                    <Select defaultValue={0} label="Institution" value={institutionId} onChange={(e) => { setInstitutionId(e.target.value) }}>
                                        <MenuItem value={0}><em>None</em></MenuItem>
                                        {institutions.map(institution => { return (<MenuItem key={institution.id} value={institution.id}> {institution.name} </MenuItem>) })}
                                    </Select>
                                </FormControl>
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField required
                                    fullWidth
                                    onChange={e => setFirstName(e.target.value)}
                                    label="First Name"
                                    value={firstName}
                                    autoFocus />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField required
                                    fullWidth
                                    value={lastName}
                                    onChange={e => setLastName(e.target.value)}
                                    label="Last Name" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField required
                                    type="tel"
                                    fullWidth
                                    value={mobileNumber}
                                    onChange={e => setMobileNumber(e.target.value)}
                                    label="Mobile Number" />
                            </Grid>
                            <Grid item xs={12}>
                                <TextField required
                                    type="email"
                                    value={emailAddress}
                                    fullWidth
                                    onChange={e => setEmailAddress(e.target.value)}
                                    label="Email Address" />
                                {!IsvalidEmail && { validationError }}
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField type="password"
                                    required
                                    fullWidth
                                    value={password}
                                    onChange={e => setPassword(e.target.value)}
                                    label="Password" />
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <TextField type="password"
                                    required
                                    fullWidth
                                    value={confirmPassword}
                                    onChange={e => setConfirmPassword(e.target.value)}
                                    label="Confirm Password" />
                            </Grid>

                        </Grid>
                        <Dialog
                            open={open}
                            onClose={handleClose}
                            aria-labelledby="scroll-dialog-title"
                            aria-describedby="scroll-dialog-description" >
                            <DialogTitle id="scroll-dialog-title">Registration successful!</DialogTitle>
                            <DialogContent>
                                <DialogContentText id="scroll-dialog-description" tabIndex={-1}>
                                    We have sent an email with a confirmation link to your email address. Please allow 2-5 minutes for this message to arrive.
                                </DialogContentText>
                            </DialogContent>
                            <DialogActions>
                                <Button onClick={handleClose}>Ok</Button>
                            </DialogActions>
                        </Dialog>
                        <Button type="submit" color='primary' disabled={isLoading || !isFormValid()}
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}>
                            Sign Up
                        </Button>
                        <Grid container spacing={2}>
                            <Grid item xs={12} sm={6}>
                                <Button onClick={() => AddInstitution()} color='inherit'>Add Institution</Button>
                            </Grid>
                            <Grid item xs={12} sm={6}>
                                <Button onClick={() => SignInPage()} color='inherit'>Already have an account? Sign in</Button>
                            </Grid>
                        </Grid>

                    </Box>
                </Box>
            </Grid>
        </Grid>
    )
}

export default SideSignUp