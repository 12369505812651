import React, { useState } from 'react';
import { useNavigate, useSearchParams } from 'react-router-dom';
import LockOutlinedIcon from '@mui/icons-material/LockOutlined';
import { Paper, CircularProgress, Typography, CssBaseline, Alert } from '@mui/material';
import { Grid, Box, TextField, Button, Avatar } from '@mui/material';
import { blue } from '@mui/material/colors';
import AccountAuth from '../../state/APIConnect/Account';
import RaiseMyHandImageofWoman from '../../assests/users/RaiseMyHand.jpeg'

const SetNewPassword = () => {
    const [newPassword, setNewPassword] = useState('');
    const [confimrPassword, setConfirmPassword] = useState('');
    const [isLoading, setIsLoading] = useState(false);
    const [searchParams] = useSearchParams()
    const [isError, setIsError] = useState(false);
    const [message, setMessage] = useState("");
    const navigate = useNavigate();
    const userId = searchParams.get("userId")
    const code = searchParams.get("code")

    const isFormValid = () => {
        let valid = true;
        if (newPassword === '' || confimrPassword === '') {
            valid = false;
        }
        return valid;
    }

    const handleSubmit = async (event) => {
        try {
            event.preventDefault();
            setIsLoading(true);
            await AccountAuth.SetNewPassword(userId, newPassword, confimrPassword, code).then((response) => {
                if (response.isSuccess) {
                    setMessage(response.message);
                    setNewPassword('')
                    setConfirmPassword('')
                    navigate('/login', { replace: true });
                } else {
                    setMessage(`${response.message} Make sure you are using the latest link.`);
                    setIsError(true);
                }
                setIsLoading(false);
            })
        } catch (error) {
            setIsLoading(false);
            setMessage(error)
            setIsError(true);
        }
    }


    return (
        <Grid container component="main" sx={{ height: '90vh' }}>
            <CssBaseline />
            <Grid item xs={false} sm={4} md={7}
                sx={{
                    // backgroundImage: 'url(https://source.unsplash.com/random)',
                    backgroundImage: `url(${RaiseMyHandImageofWoman})`, // Use the imported image
                    backgroundRepeat: 'no-repeat',
                    backgroundColor: (t) =>
                        t.palette.mode === 'light' ? t.palette.grey[50] : t.palette.grey[900],
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                }} />
            <Grid item xs={12} sm={8} md={5} component={Paper} elevation={6} square >
                <Box sx={{ my: 4, mx: 2, display: 'flex', flexDirection: 'column', alignItems: 'center' }}>
                    <Avatar sx={{ m: 1, bgcolor: 'primary.main' }}>
                        <LockOutlinedIcon />
                    </Avatar>
                    <Typography component="h1" variant="h5" color="inherit">
                        Setup new Password
                    </Typography>

                    <Box component="form" noValidate onSubmit={handleSubmit} sx={{ '& .MuiTextField-root': { m: 1, } }} autoComplete="off">

                        <TextField
                            fullWidth
                            label="New Password"
                            type="password"
                            value={newPassword}
                            onChange={e => setNewPassword(e.target.value)} />

                        <TextField
                            fullWidth
                            label="Confirm Password"
                            type="password"
                            value={confimrPassword}
                            onChange={e => setConfirmPassword(e.target.value)} />

                        <Button disabled={isLoading || !isFormValid()}
                            type="submit"
                            fullWidth
                            variant="contained"
                            sx={{ mt: 3, mb: 2 }}>
                            Setup new Password
                            {isLoading && (
                                <CircularProgress size={24} sx={{ color: blue[500], position: 'absolute', top: '50%', left: '50%', marginTop: '-12px', marginLeft: '-12px' }} />
                            )}
                        </Button>
                        {isError && (<Alert variant="outlined" severity="error"> {message} </Alert>)}
                    </Box>
                </Box>
            </Grid>
        </Grid >
    )
}

export default SetNewPassword