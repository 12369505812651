import React, { useState } from 'react';
import { TextField, Grid, Tab, Box } from '@mui/material'
import { TabContext, TabList, TabPanel } from '@mui/lab';

const QuartelyViewComponent = (props) => {
    const monthlyReports = props.monthlyReport;
    const [value, setValue] = useState('0');

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    return (
        <Box sx={{ width: '100%', typography: 'body1' }}>
            <TabContext value={value}>
                <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                    <TabList onChange={handleChange}>
                        {monthlyReports.map((tab, index) => (
                            <Tab key={index} label={tab.month} value={index.toString()} />
                        ))}
                    </TabList>
                </Box>

                {monthlyReports.map((tab, index) => (
                    <TabPanel key={index} value={index.toString()}>
                        <Grid container spacing={2}>

                            <Grid item xs={12} sm={4}>
                                <TextField disabled fullWidth value={tab.statusName} label="Status" />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField disabled fullWidth value={tab.progress} label="Progress" />
                            </Grid>

                            <Grid item xs={12} sm={4}>
                                <TextField disabled fullWidth value={tab.deviationReason} label="Deviation Reason" />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField multiline disabled rows={6} fullWidth value={tab.progressNative} label="Progress Narrative" />
                            </Grid>

                            <Grid item xs={12} sm={6}>
                                <TextField multiline disabled rows={6} fullWidth value={tab.correctiveMeasures} label="Obstacles (Narrative)" />
                            </Grid>

                        </Grid>
                    </TabPanel>
                ))}

            </TabContext>
        </Box>
    )
}

export default QuartelyViewComponent