import React, { useCallback, useEffect, useMemo, useState } from 'react';
import MaterialReactTable from 'material-react-table';
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, IconButton } from '@mui/material';
import { MenuItem, Select, FormControl, InputLabel, Stack, Tooltip } from '@mui/material';
import { Delete } from '@mui/icons-material';
import Header from '../../../../components/Table/Header';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import { toast } from 'react-toastify';
import { useConfirm } from 'material-ui-confirm';

const HIVActivitySubActivityPage = () => {
  const [createModalOpen, setCreateModalOpen] = useState(false);
  const [isError, setIsError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isRefetching, setIsRefetching] = useState(false);
  const [tableData, setTableData] = useState([]);
  const [validationErrors, setValidationErrors] = useState({});
  const [rowCount, setRowCount] = useState(0);
  const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 10 });
  const confirm = useConfirm();
  const [refresh, setRefresh] = useState(false);
  let platformId = 2;

  const handleCancelRowEdits = () => {
    setValidationErrors({});
  };

  const handleDeleteRow = useCallback((row) => {
    let actData = row.original;
    confirm({
      title: `Are you sure you want to delete?`, cancellationText: `No`, confirmationText: `Yes`,
      description: `This will permanently delete: ${actData.interventionName} & ${actData.activityName}.`
    }).then(async () => {
      setIsLoading(true);
      let data = { "id": actData.id, "activityId": actData.activityId, "subActivityId": actData.subActivityId }
      const resp = await NSPMonitorAuth.DeleteActivitySubActivity(data);
      setIsLoading(false);
      if (resp.content === "") {
        toast.error("Error occured while attemping to Delele.Please Contact Admininstrator", { position: "top-right", })
      }
      if (resp.content?.isSuccess) {
        setRefresh(!refresh);
        toast.success(resp.content.message, { position: "top-right", });
      } else {
        toast.error(resp.content.message, { position: "top-right", })
      }
    }).catch(error => {
      console.log("Deletion cancelled." + error)
      toast.error(error, { position: "top-right", })
    });
    tableData.splice(row.index, 1);
    setTableData([...tableData]);
  }, [tableData, confirm, refresh]);


  const getCommonEditTextFieldProps = useCallback(
    (cell) => {
      return {
        error: !!validationErrors[cell.id],
        helperText: validationErrors[cell.id],
        onBlur: (event) => {
          const isValid = true;
          if (!isValid) {
            //set validation error for cell if invalid
            setValidationErrors({
              ...validationErrors,
              [cell.id]: `${cell.column.columnDef.header} is required`,
            });
          } else {
            //remove validation error for cell if valid
            delete validationErrors[cell.id];
            setValidationErrors({
              ...validationErrors,
            });
          }
        },
      };
    },
    [validationErrors],
  );

  const GetPillarInterventions = async (pageIndex, pageSize) => {
    if (!tableData.length) {
      setIsLoading(true);
    } else {
      setIsRefetching(true);
    }

    try {
      const res = await NSPMonitorAuth.GetAllActivitySubActivity(null, platformId, pageIndex, pageSize);
      setTableData(res.content.items);
      setRowCount(res.content.totalItems);
    } catch (error) {
      setIsError(true);
      console.error(error);
      return;
    } finally {
      setIsError(false);
      setIsLoading(false);
      setIsRefetching(false);
    }
  }


  useEffect(() => {
    GetPillarInterventions(pagination.pageIndex, pagination.pageSize);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tableData.length, pagination.pageIndex, pagination.pageSize, refresh])

  const columns = useMemo(
    () => [
      {
        accessorKey: 'id',
        header: 'ID',
        enableColumnOrdering: false,
        enableEditing: false, //disable editing on this column
        enableSorting: false,
        size: 10,
      },
      {
        accessorKey: 'activityName',
        header: 'Activity Name',
        size: 500,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),
      },
      {
        accessorKey: 'subActivityName',
        header: 'Sub-Activity Name',
        size: 500,
        muiTableBodyCellEditTextFieldProps: ({ cell }) => ({
          ...getCommonEditTextFieldProps(cell),
        }),

      },
    ],
    [getCommonEditTextFieldProps],
  );

  return (
    <>
      <MaterialReactTable displayColumnDefOptions={{ 'mrt-row-actions': { muiTableHeadCellProps: { align: 'center', }, size: 120, }, }}
        columns={columns}
        muiTable
        data={tableData}
        editingMode="modal" //default
        enableColumnOrdering
        enablePagination
        rowCount={rowCount}
        enableEditing
        manualPagination
        muiTableBodyCellEditTextFieldProps={{ multiline: true }}
        onPaginationChange={setPagination}
        onEditingRowCancel={handleCancelRowEdits}
        positionGlobalFilter="left"
        state={{
          isLoading,
          pagination,
          showAlertBanner: isError,
          showProgressBars: isRefetching,

        }}
        renderRowActions={({ row, table }) => (
          <Box sx={{ display: 'flex', gap: '1rem' }}>
            <Tooltip arrow placement="right" title="Delete">
              <IconButton color="error" onClick={() => handleDeleteRow(row)}>
                <Delete />
              </IconButton>
            </Tooltip>
          </Box>
        )}
        renderTopToolbarCustomActions={() => (
          <>
            <Button
              color="primary"
              onClick={() => setCreateModalOpen(true)}
              variant="contained" >
              Add Activity - SubActivity
            </Button>
            <Header subtitle="Activity - SubActivity" />
          </>
        )} />
      <CreateNewAccountModal
        open={createModalOpen}
        onClose={() => setCreateModalOpen(false)}
        refresh={refresh}
        platformId={platformId}
        setRefresh={() => setRefresh(!refresh)} />
    </>
  )
}

export const CreateNewAccountModal = ({ open, onClose, refresh, platformId, setRefresh }) => {
  const [subActivities, setSubActivities] = useState([]);
  const [activities, setActivities] = useState([]);
  const [subActivityId, setSubActivityId] = useState(0);
  const [activityId, setActivityId] = useState(0);
  const [pagination] = useState({ pageIndex: 0, pageSize: 100 });
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (event) => {
    try {
      event.preventDefault();
      setIsLoading(true)
      const data = { "id": 0, "activityId": activityId, "subActivityId": subActivityId, "platformId": platformId };
      const resp = await NSPMonitorAuth.UpsertActivitySubActivity(data);
      setIsLoading(false)
      if (resp.content?.isSuccess) {
        toast.success(resp.content.message, { position: "top-right", });
        onClose();
        setRefresh(!refresh);
        setActivityId(0);
        setSubActivityId(0);
      } else {
        toast.error(resp.content.message, { position: "top-right", })
      }
    } catch (error) {
      setIsLoading(false)
      toast.error(error, { position: "top-right", })
    }
  };

  const isFormValid = () => {
    let valid = true;
    if (activityId === '' || activityId < 1) {
      valid = false;
    }
    if (subActivityId === '' || subActivityId < 1) {
      valid = false;
    }
    return valid;
  }


  useEffect(() => {
    const GetAllActivies = async () => {
      const res = await NSPMonitorAuth.GetAllActivies(null, platformId, pagination.pageIndex, pagination.pageSize);
      setActivities(res.content.items);
    }

    const GetAllInterventions = async () => {
      const res = await NSPMonitorAuth.GetAllSubActivities(null, platformId, pagination.pageIndex, pagination.pageSize);
      setSubActivities(res.content.items)
    }


    GetAllActivies();
    GetAllInterventions();
  }, [pagination.pageIndex, pagination.pageSize, platformId])

  return (
    <Dialog open={open} fullWidth maxWidth={'xl'}>
      <DialogTitle textAlign="center">Link Activity with SubActivity</DialogTitle>
      <DialogContent>
        <form onSubmit={(e) => e.preventDefault()}>
          <Stack sx={{ width: '100%', minWidth: { xs: '300px', sm: '360px', md: '400px' }, gap: '1.5rem', paddingTop: '10px' }}>
            <FormControl variant="outlined">
              <InputLabel>Activity</InputLabel>
              <Select defaultValue={0} label="Activity" value={activityId} onChange={(e) => { setActivityId(e.target.value) }}>
                <MenuItem value={0} defaultValue ><em>None</em></MenuItem>
                {activities ? activities.map(activity => {
                  return (<MenuItem key={activity.id} value={activity.id}> {activity.name} </MenuItem>)
                }) : <MenuItem key={0} value={0}>{'No Activity'} </MenuItem>}
              </Select>
            </FormControl>

            <FormControl variant="outlined">
              <InputLabel>SubActivity</InputLabel>
              <Select defaultValue={0} label="SubActivity" value={subActivityId} onChange={(e) => { setSubActivityId(e.target.value) }}>
                <MenuItem value={0} defaultValue ><em>None</em></MenuItem>
                {subActivities ? subActivities.map(subActivity => {
                  return (<MenuItem key={subActivity.id} value={subActivity.id}> {subActivity.name} </MenuItem>)
                }) : <MenuItem key={0} value={0}>{'No SubActivities'} </MenuItem>}
              </Select>
            </FormControl>
          </Stack>
        </form>
      </DialogContent>
      <DialogActions sx={{ p: '1.25rem' }}>
        <Button onClick={onClose}>Cancel</Button>
        <Button color="primary" onClick={handleSubmit} variant="contained" disabled={isLoading || !isFormValid()}>Add</Button>
      </DialogActions>
    </Dialog>
  );
};

export default HIVActivitySubActivityPage