import React, { useEffect, useMemo, useState } from 'react'
import { Box } from '@mui/material'
import { makeStyles } from '@mui/styles'
import styles from "../../assests/jss/layouts/dashboardStyle.js"
import Header from '../../components/Table/Header.js'
import Card from '../../components/CustomCards/Card.js'
import CardHeader from '../../components/CustomCards/CardHeader.js'
import CardIcon from '../../components/CustomCards/CardIcon.js'
import GridContainer from '../../components/Grid/GridContainer.js'
import GridItem from '../../components/Grid/GridItem.js'
import CardFooter from '../../components/CustomCards/CardFooter.js'
import { DateRange, Grading, ChromeReaderMode, FileCopy, StarHalf, AccessTime } from '@mui/icons-material'
import NSPMonitorAuth from '../../state/APIConnect/RaiseMyhand/NSPMonitor.js'
import CardBody from '../../components/CustomCards/CardBody.js'
import InformationWindow from '../../components/GoogleMap/InformationWindow.js'
import Sponsors from './Sponsors.jsx'
// eslint-disable-next-line no-unused-vars
import { Chart } from "chart.js/auto";
import { Bar } from "react-chartjs-2";
import InstitutionProvinceReportv1 from './InstitutionProvinceReportv1.js'
import { Pie, Column } from '@ant-design/plots';


const useStyles = makeStyles(styles);


const Dashboard = () => {
    const [marker, setMarker] = useState([]);
    const classes = useStyles();
    const [intervention, setIntervention] = useState({ Name: '', Total: 0, LastDateModify: '', lastModify: '' });
    const [activity, setActivity] = useState({ Name: '', Total: 0, LastDateModify: '', lastModify: '' });
    const [subActivity, setSubActivity] = useState({ Name: '', Total: 0, LastDateModify: '', lastModify: '' });
    const [achievement, setAchievement] = useState({ Name: '', Total: 0, LastDateModify: '', lastModify: '' });
    const [pillarAchievementMaxDate, SetPillarAchievementMaxDate] = useState([]);
    const [institutionProvinces, SetInstitutionProvinces] = useState([]);
    const [institutionProvincesMaxDate, SetInstitutionProvincesMaxDate] = useState('');
    const [filterProvinceId, setFilterProvinceId] = useState(0);
    const [filterProvinceName, setFilterProvinceName] = useState("All Provinces");
    // const [saColor] = useState({ Green: '#007A4D', Black: '#000000', White: '#FFFFFF', Gold: '#FFB612', Red: '#DE3831', Blue: '#002395' });
    const [statusReport, setStatusReport] = useState([]);
    const [deviationReasonReport, setDeviationReasonReport] = useState([]);
    let platformId = 1;


    const GetMainDashBoardReport = async () => {
        const res = await NSPMonitorAuth.GetMainDashBoardReport(platformId);
        const data = res.content;

        const intervention = data.intervention;
        const dateIntervention = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(intervention.lastDateModify));
        const newIntervention = { Name: intervention.name, Total: intervention.total, LastDateModify: dateIntervention, lastModify: intervention.lastModify };
        setIntervention(newIntervention)

        const activity = data.activity;
        const dateActivity = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(activity.lastDateModify));
        const newActivity = { Name: activity.name, Total: activity.total, LastDateModify: dateActivity, lastModify: activity.lastModify };
        setActivity(newActivity)

        const subActivity = data.subActivity;
        const dateSubActivity = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(subActivity.lastDateModify));
        const newSubActivity = { Name: subActivity.name, Total: subActivity.total, LastDateModify: dateSubActivity, lastModify: subActivity.lastModify };
        setSubActivity(newSubActivity)

        const achievement = data.achievement;
        const dateAchievement = new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(new Date(subActivity.lastDateModify));
        const newAchievement = { Name: achievement.name, Total: achievement.total, LastDateModify: dateAchievement, lastModify: achievement.lastModify };
        setAchievement(newAchievement)

        const institutionProvinces = data.institutionProvinces;
        SetInstitutionProvinces(institutionProvinces);
        const institutionProvincesDatesArray = institutionProvinces.map((element) => new Date(element.lastDateModify));
        const institutionProvinceMaxDate = new Date(Math.max(...institutionProvincesDatesArray));
        SetInstitutionProvincesMaxDate(new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(institutionProvinceMaxDate));

        const pillarAchievement = data.pillarAchievement;
        const pillarAchievementDatesArray = pillarAchievement.map((element) => new Date(element.lastDateModify));
        const pillarAchievementMaxDatea = new Date(Math.max(...pillarAchievementDatesArray));
        SetPillarAchievementMaxDate(new Intl.DateTimeFormat('en-US', { dateStyle: 'full', timeStyle: 'short' }).format(pillarAchievementMaxDatea))

    }

    const GetAllInstitutionForMapDisplay = async () => {
        const res = await NSPMonitorAuth.GetAllInstitutionForMapDisplay();
        setMarker(res.content)
    }

    const GetAchievementStatusReport = async () => {
        const res = await NSPMonitorAuth.GetAchievementStatusReport();
        setStatusReport(res.content)
    }

    const GetAchievementDeviationReason = async () => {
        const res = await NSPMonitorAuth.GetAchievementDeviationReason();
        setDeviationReasonReport(res.content)
    }
    const piesData = useMemo(() => institutionProvinces, [institutionProvinces]);

    const mapMarker = useMemo(() => marker, [marker]);

    const handleChartClick = (event, elements) => {
        if (elements.length > 0) {
            const index = elements[0].index;
            const clickedBarData = institutionProvinces[index];
            setFilterProvinceId(clickedBarData.id)
            setFilterProvinceName(clickedBarData.name);
        }
    };

    useEffect(() => {
        GetMainDashBoardReport();
        GetAllInstitutionForMapDisplay();
        GetAchievementStatusReport();
        GetAchievementDeviationReason();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const getRandomColor = () => {
        const letters = '0123456789ABCDEF';
        let color = '#';
        for (let i = 0; i < 6; i++) {
            color += letters[Math.floor(Math.random() * 16)];
        }
        return color;
    };
    const backgroundColors = institutionProvinces.map(() => getRandomColor());

    const configStatusReport = {
        appendPadding: 10,
        data: statusReport,
        angleField: 'numberOfReports',
        colorField: 'name',
        radius: 0.8,
        label: {
            type: 'outer',
            content: '{name} {percentage}',
        },
        interactions: [
            {
                type: 'pie-legend-active',
            },
            {
                type: 'element-active',
            },
        ],
    };

    const configDeviationReasonReport = {
        data: deviationReasonReport,
        xField: 'name',
        yField: 'numberOfReports',
        seriesField: '',
        // color: ({ type }) => {
        //     if (type === '10-30分' || type === '30+分') {
        //         return paletteSemanticRed;
        //     }

        //     return brandColor;
        // },
        label: {
            content: (originData) => {
                const val = parseFloat(originData.value);

                if (val < 0.05) {
                    return (val * 100).toFixed(1) + '%';
                }
            },
            offset: 10,
        },
        legend: false,
        
    };

    return (

        <>
            <Box m="20px">
                {/* HEADER */}
                <Box display="flex" justifyContent="space-between" alignItems="center">
                    <Header title="DASHBOARD" subtitle="Welcome to NSP - GBVF Monitor dashboard" />
                </Box>

                <GridContainer>

                    <GridItem xs={12} sm={6} md={3}>
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="blue">
                                    <ChromeReaderMode />
                                </CardIcon>
                                <p className={classes.cardCategory}>Total Interventions</p>
                                <h3 className={classes.cardTitle}>{intervention.Total}</h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    <DateRange /> {intervention.LastDateModify}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={3}>
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="success">
                                    <Grading />
                                </CardIcon>
                                <p className={classes.cardCategory}>Total Activities</p>
                                <h3 className={classes.cardTitle}>{activity.Total}</h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    <DateRange /> {activity.LastDateModify}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={3}>
                        <Card>
                            <CardHeader color="success" stats icon>
                                <CardIcon color="yellow">
                                    <FileCopy />
                                </CardIcon>
                                <p className={classes.cardCategory}>Total Sub-Activities</p>
                                <h3 className={classes.cardTitle}>{subActivity.Total}</h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    <DateRange /> {subActivity.LastDateModify}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={3}>
                        <Card>
                            <CardHeader color="danger" stats icon>
                                <CardIcon color="danger">
                                    <StarHalf />
                                </CardIcon>
                                <p className={classes.cardCategory}>Total Achievement</p>
                                <h3 className={classes.cardTitle}>{achievement.Total}</h3>
                            </CardHeader>
                            <CardFooter stats>
                                <div className={classes.stats}>
                                    <DateRange /> {achievement.LastDateModify}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>

                </GridContainer>

                {/* GRID & CHARTS  ChartistGraph*/}

                <GridContainer>

                    <GridItem xs={12} sm={4} md={4}>
                        <Card chart>
                            <Pie {...configStatusReport} />
                            <CardBody>
                            </CardBody>
                            <CardFooter chart>
                                <div className={classes.stats}>
                                    <AccessTime /> Last Updated : {institutionProvincesMaxDate}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={4} md={4}>
                        <Card chart>
                            <Column {...configDeviationReasonReport} />
                            <CardBody>
                            </CardBody>
                            <CardFooter chart>
                                <div className={classes.stats}>
                                    <AccessTime /> Last Updated : {institutionProvincesMaxDate}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={4} md={4}>
                        <Card chart>
                            <Pie {...configStatusReport} />
                            <CardBody>
                            </CardBody>
                            <CardFooter chart>
                                <div className={classes.stats}>
                                    <AccessTime /> Last Updated : {institutionProvincesMaxDate}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>
                    <GridItem xs={12} sm={6} md={6}>
                        <Card chart>
                            <CardHeader mb={6}>
                                <Box marginBottom={4}>
                                    <Header subtitle="Total Monthly Reports per Pillar for 2023" />
                                </Box>
                                {/* <Column {...ColumnData} /> */}
                                <Bar data={{
                                    labels: piesData.map((data) => data.name),
                                    datasets: [
                                        {
                                            label: "",
                                            data: piesData.map((data) => data.total),
                                            // backgroundColor: backgroundColors,
                                            borderRadius: 5,
                                        },
                                    ],
                                }}
                                    options={{
                                        // plugins: {
                                        //     title: {
                                        //         text: "Revenue Source",
                                        //     },
                                        // },
                                        onClick: handleChartClick
                                    }}
                                />
                            </CardHeader>
                            <CardBody>
                            </CardBody>
                            <CardFooter chart>
                                <div className={classes.stats}>
                                    <AccessTime /> Last Updated : {pillarAchievementMaxDate}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={6} md={6}>
                        <Card chart>
                            <InstitutionProvinceReportv1 provinceId={filterProvinceId} provinceName={filterProvinceName} />
                            <CardBody>
                            </CardBody>
                            <CardFooter chart>
                                <div className={classes.stats}>
                                    <AccessTime /> Last Updated : {institutionProvincesMaxDate}
                                </div>
                            </CardFooter>
                        </Card>
                    </GridItem>

                    <GridItem xs={12} sm={12} md={12}>
                        <InformationWindow markers={mapMarker} />
                    </GridItem>

                </GridContainer>


            </Box>
            <Sponsors />
        </>
    )
}

export default Dashboard