import {
    ProfileOutlined, ReadOutlined, UsergroupAddOutlined, DashboardOutlined, UnorderedListOutlined
} from "@ant-design/icons";
import { AssuredWorkload } from "@mui/icons-material";
import { AppSettings } from "./AppSettings";
// import {PublicOutlined, ShoppingCartOutlined, Groups2Outlined, ReceiptLongOutlined, PointOfSaleOutlined,
//     TodayOutlined, CalendarMonthOutlined, AdminPanelSettingsOutlined, TrendingUpOutlined, PieChartOutlined
// } from "@mui/icons-material";


const navItems = [
    {
        text: "Dashboard",
        icon: <DashboardOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "dashboard",
        roles: [null]

    },
    {
        text: "Admin ",
        icon: null,
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Users",
        icon: <UsergroupAddOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "users",
        roles: [AppSettings.Roles.SuperAdmin]
    },
    {
        text: "Users",
        icon: <UsergroupAddOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "users",
        roles: [AppSettings.Roles.Admin]
    },
    // {
    //     text: "User Roles",
    //     icon: <TeamOutlined style={{ color: AppColorTheme.textColor }} />,
    //     link: "Role"
    // },
    {
        text: "Institution",
        icon: <AssuredWorkload style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "institution",
        roles: [AppSettings.Roles.SuperAdmin]
    },
    {
        text: "NSP-GBVF Monitor",
        icon: null,
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin, AppSettings.Roles.General]
    },
    {
        text: "Intervention",
        icon: <ProfileOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "interventionPillars",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Activities",
        icon: <UnorderedListOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "activityIntervention",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "SubActivity",
        icon: <ReadOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "subActivityActivity",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Monthly Achievement",
        icon: <ReadOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "monthlyAchievement",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Quarterly Achievement",
        icon: <ReadOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "quarterlyAchievement",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Beyond Presidential Summit II TRacker",
        icon: null,
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin, AppSettings.Roles.General]
    },
    {
        text: "Fast-track Item",
        icon: <ProfileOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "bpInterventionPillar",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Areas of Emphasis",
        icon: <UnorderedListOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "bpActivities",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Summit Resolution Report",
        icon: <ReadOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "summitResolutionProgressReport",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "NSP - HIV & AIDS",
        icon: null,
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Interventions",
        icon: <ProfileOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "hivInterventionPillar",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Activities",
        icon: <UnorderedListOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "hivActivityIntervention",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "SubActivity",
        icon: <ReadOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "hivSubActivityActivity",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Monthly Achievement",
        icon: <ReadOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "monthlyAchievement",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Kinshasa Declaration - 2021",
        icon: null,
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin, AppSettings.Roles.General]
    },
    {
        text: "African Union Commitment",
        icon: <ProfileOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "hivInterventionPillar",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    {
        text: "Programme",
        icon: <UnorderedListOutlined style={{ color: AppSettings.AppColorTheme.textColor }} />,
        link: "hivActivityIntervention",
        roles: [AppSettings.Roles.SuperAdmin, AppSettings.Roles.Admin]
    },
    // {
    //     text: "Client Facing",
    //     icon: null,
    // },
    // {
    //     text: "Products",
    //     icon: <ShoppingCartOutlined />,
    //     link: "products"
    // },
    // {
    //     text: "Customers",
    //     icon: <Groups2Outlined />,
    //     link: "customers"
    // },
    // {
    //     text: "Transactions",
    //     icon: <ReceiptLongOutlined />,
    //     link: "transactions"
    // },
    // {
    //     text: "Geography",
    //     icon: <PublicOutlined />,
    //     link: "geography"
    // },
    // {
    //     text: "Sales",
    //     icon: null,
    // },
    // {
    //     text: "Overview",
    //     icon: <PointOfSaleOutlined />,
    //     link: "overview"
    // },
    // {
    //     text: "Daily",
    //     icon: <TodayOutlined />,
    //     link: "daily"
    // },
    // {
    //     text: "Monthly",
    //     icon: <CalendarMonthOutlined />,
    //     link: "monthly"
    // },
    // {
    //     text: "Breakdown",
    //     icon: <PieChartOutlined />,
    //     link: "breakdown"
    // },
    // {
    //     text: "Management",
    //     icon: null,
    // },
    // {
    //     text: "Admin",
    //     icon: <AdminPanelSettingsOutlined />,
    //     link: "admin"
    // },
    // {
    //     text: "Performance",
    //     icon: <TrendingUpOutlined />,
    //     link: "performance"
    // },

];

export default navItems;
