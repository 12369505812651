import React, { useEffect, useMemo, useState } from 'react'
import { GoogleMap, InfoWindow, Marker, useJsApiLoader } from '@react-google-maps/api';
import { DashboardContainer, MapSection } from './MapStyle';
import NSPMonitorAuth from '../../state/APIConnect/RaiseMyhand/NSPMonitor';
import { AppSettings } from '../../AppSettings';


const InformationWindow = ({ markers }) => {
    const center = { lat: -28.48322, lng: 24.676997 };
    const [catergories, setCatergories] = useState([]);
    const [mapRef, setMapRef] = useState();
    const [isOpen, setIsOpen] = useState(false);
    const [infoWindowData, setInfoWindowData] = useState();
    const { isLoaded } = useJsApiLoader({
        id: 'google-map-script',
        googleMapsApiKey: AppSettings.GoogleMapAPIKey
    })

    const GetMainDashBoardReport = async () => {
        const res = await NSPMonitorAuth.GetAllCatergories();
        setCatergories(res.content)
    }

    useEffect(() => {
        GetMainDashBoardReport()
    }, [])

    const catergoriesData = useMemo(() => catergories, [catergories]);

    const onMapLoad = (map) => {
        setMapRef(map);
        const bounds = new window.google.maps.LatLngBounds();
        markers?.forEach(({ lat, lng }) => bounds.extend({ lat, lng }));
        map.fitBounds(bounds);
    };

    const handleMarkerClick = (id, lat, lng, institutionName) => {
        mapRef?.panTo({ lat, lng });
        setInfoWindowData({ id, institutionName });
        setIsOpen(true);
    };

    return isLoaded ? (
        <DashboardContainer>
            <MapSection>
                <GoogleMap
                    mapContainerClassName="map-container"
                    center={center}
                    zoom={2}
                    onLoad={onMapLoad}>
                    {markers.map(({ institutionName, lat, lng, icon }, ind) => (
                        <Marker
                            icon={`https://maps.google.com/mapfiles/ms/micons/${icon}.png`}
                            key={ind}
                            position={{ lat, lng }}
                            onClick={() => { handleMarkerClick(ind, lat, lng, institutionName) }}>
                            {isOpen && infoWindowData?.id === ind && (
                                <InfoWindow onCloseClick={() => { setIsOpen(false) }}>
                                    <h3>{infoWindowData.institutionName}</h3>
                                </InfoWindow>
                            )}
                        </Marker>
                    ))}
                    <div className="legend-container" >
                        {catergoriesData.map(({ icon, name }, id) => (
                            <div key={id}>
                                {icon && <img width="25" height="25" alt='' src={`https://maps.google.com/mapfiles/ms/micons/${icon}.png`} title={name} />}
                            </div>
                        ))}
                    </div>
                </GoogleMap>
            </MapSection>
        </DashboardContainer>
    ) : <></>

}

export default InformationWindow