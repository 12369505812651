import React, { useMemo, useState, useEffect } from 'react';
import { MaterialReactTable } from 'material-react-table';
import NSPMonitorAuth from '../../state/APIConnect/RaiseMyhand/NSPMonitor';
import Header from '../../components/Table/Header';

const InstitutionProvinceReportv1 = ({ provinceId, provinceName }) => {
    const [pagination, setPagination] = useState({ pageIndex: 0, pageSize: 5 });
    const [tableData, setTableData] = useState([]);
    const [rowCount, setRowCount] = useState(0);


    const GetInstitutionProvinceReport = async () => {
        const res = await NSPMonitorAuth.GetInstitutionProvinceReportByProvinceId(null, provinceId, pagination.pageIndex, pagination.pageSize);
        setTableData(res.content.items);
        setRowCount(res.content.totalItems)
    }

    useEffect(() => {
        GetInstitutionProvinceReport();

        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [tableData.length, pagination.pageIndex, pagination.pageSize, provinceId])

    const columns = useMemo(
        () => [
            // {
            //     accessorKey: 'id',
            //     header: 'ID',
            //     enableColumnOrdering: false,
            //     enableEditing: false, //disable editing on this column
            //     enableSorting: false,
            //     size: 10,
            // },
            {
                accessorKey: 'institutionName',
                header: 'Institution',
                size: 150,
                enableEditing: false,
            },
            {
                accessorKey: 'pillar1',
                header: 'Pillar 1',
                size: 10,
            },
            {
                accessorKey: 'pillar2',
                header: 'Pillar 2',
                size: 10,
            },
            {
                accessorKey: 'pillar3',
                header: 'Pillar 3',
                size: 10,
            },
            {
                accessorKey: 'pillar4',
                header: 'Pillar 4',
                size: 10,
            },
            {
                accessorKey: 'pillar5',
                header: 'Pillar 5',
                size: 10,
            },
            {
                accessorKey: 'pillar6',
                header: 'Pillar 6',
                size: 10,
            }

        ],
        [],
    );
    return (
        <MaterialReactTable displayColumnDefOptions={{ 'mrt-row-actions': { muiTableHeadCellProps: { align: 'center', }, size: 120, }, }}
            columns={columns}
            data={tableData}
            initialState={{ columnVisibility: { id: true } }} //hide firstName column by default           
            rowCount={rowCount}
            manualPagination
            enablePagination
            onPaginationChange={setPagination}
            state={{
                pagination
            }}
            renderTopToolbarCustomActions={() => (
                <Header subtitle={provinceName} />
            )}
        />
    )
}

export default InstitutionProvinceReportv1