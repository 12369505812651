import RestUtilities from '../../RestUtilities';


export default class CommonAuth {

    static async GetAllProvinces() {
        return await RestUtilities.get(`Common/GetAllProvinces`);
    }

    static async GetDistrictMunicipalityByProvinceId(provinceId, searchString, pageNumber, pageSize){
        //Since MUI Table start at 0 and the API start at 1 we need to add 1
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`Common/GetDistrictMunicipalityByProvinceId?provinceId=${provinceId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`Common/GetDistrictMunicipalityByProvinceId?provinceId=${provinceId}&searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetLocalsByDistrictMunicipalityId(districtMunicipalityId, searchString, pageNumber, pageSize){
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`Common/GetLocalsByDistrictMunicipalityId?districtMunicipalityId=${districtMunicipalityId}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`Common/GetLocalsByDistrictMunicipalityId?districtMunicipalityId=${districtMunicipalityId}&searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetAllCyclicByCyclicTypeId(cycyleTypeId) {
        return await RestUtilities.get(`Common/GetAllCyclicByCyclicTypeId?cycyleTypeId=${cycyleTypeId}`);
    }

    static async GetAllCyclics() {
        return await RestUtilities.get(`Common/GetAllCyclics`);
    }

    static async DownloadFileByFileName(fileName) {
        return await RestUtilities.getBlob(`Common/DownloadFile?filename=${fileName}`, fileName);
    }
}