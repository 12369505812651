import React, { useMemo, useState } from "react";
import { Box, useMediaQuery } from "@mui/material";
import { Outlet } from "react-router-dom";
import Header from "./Header";
import MainDrawer from "./Drawer";
import { useSelector } from "react-redux";
import { useGetUserQuery } from "../../state/api";
import AuthUser from "../../hooks/AuthUser";

const MainLayout = () => {
    const isNonMobile = useMediaQuery("(min-width: 600px)");
    const [isSidebarOpen, setIsSidebarOpen] = useState(false);
    const userId = useSelector((state) => state.global.userId);
    const { data } = useGetUserQuery(userId);

    const userInfo = JSON.parse(AuthUser.getUserInfo());
    const user = useMemo(() => userInfo, [userInfo]);

    return (
        <Box display={isNonMobile ? "flex" : "block"} width="100%" height="100%">
            <Header user={data || {}} isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
            <Box sx={{ display: "flex" }}>
                <MainDrawer user={user || {}} isNonMobile={isNonMobile} drawerWidth="250px" isSidebarOpen={isSidebarOpen} setIsSidebarOpen={setIsSidebarOpen} />
            </Box>
            <Box component="main" sx={{ flexGrow: 2, p: 0, paddingTop:'64px' }}>
                <Outlet />
            </Box>
        </Box>
    )
}

export default MainLayout