import PropTypes from 'prop-types';
import { useRef, useState } from 'react';
import { useTheme } from '@mui/material/styles';
import { Avatar, Box, CardContent, ClickAwayListener, Typography } from '@mui/material';
import { Grid, IconButton, Paper, Popper, Stack, Tab, Tabs, Card } from '@mui/material';
import { UserOutlined } from '@ant-design/icons';
import { Fingerprint, PersonAdd } from '@mui/icons-material';
import { Link, useNavigate } from 'react-router-dom';
import useAuth from '../../../../../hooks/useAuth';
import avatar1 from '../../../../../assests/users/avatar.jpg';
import Transitions from '../../../../Transitions/Transitions';
import ProfileTab from '../Profile/ProfileTab.js';
import FlexBetween from '../../../../FlexBetween';
import AccountAuth from '../../../../../state/APIConnect/Account';

// tab panel wrapper
function TabPanel({ children, value, index, ...other }) {
    return (
        <div role="tabpanel" hidden={value !== index} id={`profile-tabpanel-${index}`} aria-labelledby={`profile-tab-${index}`} {...other}>
            {value === index && children}
        </div>
    );
}

TabPanel.propTypes = {
    children: PropTypes.node,
    index: PropTypes.any.isRequired,
    value: PropTypes.any.isRequired
};

function a11yProps(index) {
    return {
        id: `profile-tab-${index}`, 'aria-controls': `profile-tabpanel-${index}`
    };
}

const Profile = () => {
    const theme = useTheme();
    const { auth, setAuth } = useAuth();
    const navigate = useNavigate();

    const handleLogout = async () => {
        AccountAuth.signOut();
        setAuth('');
        handleToggle();
        navigate('/login', { replace: true });
    };

    const anchorRef = useRef(null);
    const [open, setOpen] = useState(false);

    const handleToggle = () => {
        setOpen((prevOpen) => !prevOpen);
    };

    const handleClose = (event) => {
        setOpen(false);
        if (anchorRef.current && anchorRef.current.contains(event.target)) {
            return;
        }
    };

    const [value, setValue] = useState(0);

    const handleChange = (event, newValue) => {
        setValue(newValue);
    };


    const iconBackColorOpen = 'grey.300';

    return (
        <Box sx={{ flexShrink: 0, ml: 0.75 }}>
            {auth?.username ?
                (<>
                    <IconButton sx={{ p: 0.25, bgcolor: open ? iconBackColorOpen : 'transparent', borderRadius: 1, '&:hover': { bgcolor: 'secondary.lighter' } }}
                        aria-label="open profile"
                        ref={anchorRef}
                        aria-controls={open ? 'profile-grow' : undefined}
                        aria-haspopup="true"
                        color="inherit"
                        onClick={handleToggle}>
                        <Stack direction="row" spacing={2} alignItems="center" sx={{ p: 0.5 }} >
                            <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} />
                            <Typography variant="subtitle1" sx={{ fontStyle: 'italic', fontWeight: 'bold', fontVariant: 'inherit' }}>{auth.firstName} {auth.surname}</Typography>
                        </Stack>
                    </IconButton>
                    <Popper
                        placement="bottom-end"
                        open={open}
                        anchorEl={anchorRef?.current}
                        role={undefined}
                        transition
                        disablePortal
                        popperOptions={{ modifiers: [{ name: 'offset', options: { offset: [0, 9] } }] }} >
                        {({ TransitionProps }) => (
                            <Transitions type="fade" in={open} {...TransitionProps}>
                                {open && (
                                    <Paper sx={{ boxShadow: 1, width: 290, minWidth: 240, maxWidth: 290, [theme.breakpoints.down('md')]: { maxWidth: 250 } }} >
                                        <ClickAwayListener onClickAway={handleClose}>
                                            <Card elevation={0}>
                                                <CardContent sx={{ px: 2.5, pt: 3 }}>
                                                    <Grid container justifyContent="space-between" alignItems="center">
                                                        <Grid item>
                                                            <Stack direction="row" spacing={1.25} alignItems="center">
                                                                <Avatar alt="profile user" src={avatar1} sx={{ width: 32, height: 32 }} />
                                                                <Stack>
                                                                    <Typography variant="h6" color="textSecondary">{auth?.username}</Typography>
                                                                    <Typography variant="body2">{auth.firstName} {auth.surname}</Typography>
                                                                </Stack>
                                                            </Stack>
                                                        </Grid>
                                                    </Grid>
                                                </CardContent>
                                                {open && (<>
                                                    <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
                                                        <Tabs variant="fullWidth" value={value} onChange={handleChange} aria-label="profile tabs" >
                                                            <Tab sx={{
                                                                display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', textTransform: 'capitalize'
                                                            }} icon={<UserOutlined style={{ marginBottom: 0, marginRight: '10px' }} />} label="Profile" {...a11yProps(0)} />
                                                        </Tabs>
                                                    </Box>
                                                    <ProfileTab handleLogout={handleLogout} />
                                                    {/* <TabPanel value={value} index={0} dir={theme.direction}>
                                                            <ProfileTab handleLogout={handleLogout} />
                                                        </TabPanel>
                                                        <TabPanel value={value} index={1} dir={theme.direction}>
                                                            <SettingTab />
                                                        </TabPanel> */}
                                                </>
                                                )}
                                            </Card>
                                        </ClickAwayListener>
                                    </Paper>
                                )}
                            </Transitions>
                        )}
                    </Popper>
                </>) : (
                    <ClickAwayListener onClickAway={handleClose}>
                        <FlexBetween gap="1.5rem">
                            <Link to={"/login"} className="removeLink">
                                <IconButton size="small" color='inherit' >
                                    <Fingerprint /> <Typography>Login</Typography>
                                </IconButton>
                            </Link >
                            <Link to={"/signUp"} className="removeLink">
                                <IconButton size="small" color="inherit">
                                    <PersonAdd /> <Typography>SignUp</Typography>
                                </IconButton>
                            </Link>
                        </FlexBetween>
                    </ClickAwayListener>
                )
            }
        </Box >
    )
}

export default Profile