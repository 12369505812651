import { Box, Grid } from '@mui/material'
import React from 'react'
import FlexBetween from '../../../../components/FlexBetween'
import Header from '../../../../components/Table/Header'
import HIVInterventionPage from './HIVInterventionPage'
import HIVPillarInterventionPage from './HIVPillarInterventionPage'

const HIVIntervention = () => {
  return (
    <Box m="0.5rem 0.5rem">
      <FlexBetween>
        <Header title='Pillar & Interventions' />
      </FlexBetween>
      <Grid container rowSpacing={4} columnSpacing={{ xs: 1, sm: 2, md: 3 }}>
        <Grid item xs={12} sm={12} md={6}>
          <HIVInterventionPage />
        </Grid>
        <Grid item xs={12} sm={12} md={6}>
          <HIVPillarInterventionPage />
        </Grid>
      </Grid>
    </Box>
  )
}

export default HIVIntervention