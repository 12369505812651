import React, { useEffect, useState } from 'react'
import { useNavigate, useParams } from "react-router-dom";
import { Box, Grid, Button, FormControl, InputLabel, Card } from '@mui/material'
import { MenuItem, Select, TextField, Checkbox, Typography } from '@mui/material';
import NSPMonitorAuth from '../../../../state/APIConnect/RaiseMyhand/NSPMonitor';
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import dayjs from "dayjs";
import { toast } from 'react-toastify';
import CommonAuth from '../../../../state/APIConnect/Common';
import QuartelyViewComponent from './QuartelyViewComponent';
import { GetApp, GetAppOutlined } from '@mui/icons-material';


const EditQuarterlyAchievement = () => {
  const { achievementId } = useParams();
  const navigate = useNavigate();
  const [pillars, setPillars] = useState([]);
  const [interventions, setInterventions] = useState([]);
  const [activities, setActivities] = useState([]);
  const [reportCycles, setReportCycles] = useState([]);
  const [reportQuarters, setReportQuarters] = useState([]);
  const [monthlyReport, setMonthlyReport] = useState([]);
  const [pagination] = useState({ pageIndex: 0, pageSize: 100 });
  const [isLoading, setIsLoading] = useState(false);
  const [showReport, setShowReport] = useState(false);
  const [item, setItem] = useState({});

  const [reportCycleId, setReportCycleId] = useState(0);
  const [reportQuarterId, setReportQuarterId] = useState(0);
  const [reportYear, setReportYear] = useState(0);
  const [pillarId, setPillarId] = useState(0);
  const [interventionId, setInterventionId] = useState(0);
  const [activityId, setActivityId] = useState(0);
  const [isComplete, setIsComplete] = useState(false);
  const [progressNative, setProgressNative] = useState("");
  const [obtacleNarative, setObtacleNarative] = useState("");
  const [achievementsFiles, setAchievementsFiles] = useState([]);

  const handleDownload = async (fileName) => {
    try {
      await CommonAuth.DownloadFileByFileName(fileName)
    } catch (error) {
      toast.error(error, { position: "top-right", })
    }
  };

  const GetAllPillars = async () => {
    let platformId = 1;
    const res = await NSPMonitorAuth.GetAllPillars(platformId);
    setPillars(res.content);
  }

  const GetAllInterventions = async () => {
    const res = await NSPMonitorAuth.GetInterventionsByPillarId(pillarId, null, pagination.pageIndex, pagination.pageSize);
    setInterventions(res.content.items)
  }

  const GetAllActivities = async () => {
    const res = await NSPMonitorAuth.GetActivityByInterventionId(interventionId, null, pagination.pageIndex, pagination.pageSize);
    setActivities(res.content.items)
  }

  const GetReportQuartyByCycleId = async (event) => {
    const res = await CommonAuth.GetAllCyclicByCyclicTypeId(reportCycleId);
    setReportQuarters(res.content)
  }

  const GetInterventionByPillarId = async (event) => {
    let pId = event.target.value;
    setPillarId(pId);
    const res = await NSPMonitorAuth.GetInterventionsByPillarId(pId, null, pagination.pageIndex, pagination.pageSize);
    setInterventions(res.content.items)
  }

  const GetActivitiesByInterventionId = async (event) => {
    let iId = event.target.value;
    setInterventionId(iId);
    const res = await NSPMonitorAuth.GetActivityByInterventionId(iId, null, pagination.pageIndex, pagination.pageSize);
    setActivities(res.content.items)
  }

  const isFormValid = () => {
    let valid = true;
    if (pillarId === 0 || interventionId === 0 || activityId === 0 || reportQuarterId === 0) {
      valid = false;
    }
    if (progressNative === '' || obtacleNarative === '') {
      valid = false;
    }
    return valid;
  }

  const handleReportYearChange = (newValue) => {
    let toDate = newValue.toDate();
    let toYear = dayjs(toDate).year();
    setReportYear(toYear);
  };

  const GetQaurterlyReportByActivityId = async () => {
    const res = await NSPMonitorAuth.GetQaurterlyReport(reportQuarterId, reportYear, pillarId, interventionId, activityId);
    setMonthlyReport(res.content);
    setShowReport(true)
  }

  useEffect(() => {
    const GetQaurterlyReportByAchievementById = async () => {
      if (!reportCycles.length) {
        setIsLoading(true);
      }
      const res = await NSPMonitorAuth.GetQaurterlyReportByAchievementById(achievementId);
      if (res.content === '') {
        console.log('Content is null empty');
      }
      else {
        let data = res.content;
        setItem(data);
        setReportCycleId(data.cyclicTypeId)
        setReportQuarterId(data.cyclicId)
        setPillarId(data.pillarId)
        setInterventionId(data.interventationId)
        setActivityId(data.activitiesId)
        setReportYear(data.reportYear);
        setAchievementsFiles(data.achievementsFiles);
        setObtacleNarative(data.correctiveMeasures);
        setProgressNative(data.progressNative);
        setIsComplete(data.isComplete);

      }
      setIsLoading(false);
    }

    const GetAllCyclics = async () => {
      const res = await CommonAuth.GetAllCyclics();
      setReportCycles(res.content)
    }

    GetQaurterlyReportByAchievementById();
    GetAllCyclics();
    GetAllPillars();
    GetAllInterventions();
    GetAllActivities();
    GetQaurterlyReportByActivityId();
    GetReportQuartyByCycleId();


    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [achievementId, pillarId]);

  const handleBack = () => {
    navigate("/quarterlyAchievement");
  }

  const handleSubmit = async (event) => {
    event.preventDefault();
    try {
      setIsLoading(true);
      // let data = {
      //   "id": achievementId,
      //   "cyclicTypeId": reportCycleId,
      //   "cyclicId": reportQuarterId,
      //   "pillarId": pillarId,
      //   "interventationId": interventionId,
      //   "activitiesId": activityId,
      //   "progress": 0,
      //   "progressNative": progressNative,
      //   "correctiveMeasures": obtacleNarative,
      //   "reportYear": reportYear,
      //   "isComplete": isComplete,
      //   "approveOrDecline": "approve"
      // }

      const formData = new FormData();
      formData.append('id', achievementId);
      formData.append('cyclicTypeId', reportCycleId);
      formData.append('cyclicId', reportQuarterId);
      formData.append('pillarId', pillarId);
      formData.append('interventationId', interventionId);
      formData.append('activitiesId', activityId);
      formData.append('progress', 0);
      formData.append('progressNative', progressNative);
      formData.append('correctiveMeasures', obtacleNarative);
      formData.append('reportYear', reportYear);
      formData.append('isComplete', isComplete);
      formData.append('approveOrDecline', 'approve');
      // formData.append('formFiles', selectedFiles);
      // for (const file of selectedFiles) {
      //     formData.append('formFiles', file);
      // }


      const res = await NSPMonitorAuth.UpsertQaurterlyReport(formData);
      console.log("🚀 ~ file: EditQuarterlyAchievement.jsx:194 ~ handleSubmit ~ res:", res)
      setIsLoading(false);

      if (res.content?.isSuccess) {
        toast.success(res.content.message, { position: "top-right", })        
      } else {
        toast.success(res.content.message, { position: "top-right", })
      }
      handleBack();
    } catch (error) {
      setIsLoading(false);
      toast.error(error, { position: "top-right", })
    }
  }

  return (
    <Card sx={{ minWidth: 275 }}>
      <Box m="0.5rem 2.5rem">
        <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 1 }}>
          <h1>Edit Quarterly Report</h1>

          <Grid container spacing={2}>
            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Report Cycle</InputLabel>
                <Select defaultValue={0} label="Report Cycle" value={reportCycleId} onChange={GetReportQuartyByCycleId} >
                  <MenuItem value={0}><em>None</em></MenuItem>
                  {reportCycles.map(reportCycle => {
                    return (<MenuItem key={reportCycle.id} value={reportCycle.id}> {reportCycle.name} </MenuItem>)
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <InputLabel>Report Quarter</InputLabel>
                <Select defaultValue={0} label="Report Quarter" value={reportQuarterId} onChange={(e) => { setReportQuarterId(e.target.value) }} >
                  <MenuItem value={0}><em>None</em></MenuItem>
                  {reportQuarters ? (
                    reportQuarters.map(reportQuarter => (
                      <MenuItem key={reportQuarter.id} value={reportQuarter.id}> {reportQuarter.description} </MenuItem>))
                  ) : (<MenuItem value={0}>No report quarters available</MenuItem>)}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={4}>
              <FormControl fullWidth>
                <LocalizationProvider dateAdapter={AdapterDayjs}>
                  <DatePicker value={dayjs(reportYear)}
                    onChange={handleReportYearChange}
                    label={'Report Year'}
                    views={['year']} />
                </LocalizationProvider>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Pillar</InputLabel>
                <Select defaultValue={0} label="Pillar" value={pillarId} onChange={GetInterventionByPillarId} >
                  <MenuItem value={0}><em>None</em></MenuItem>
                  {pillars.map(pillar => {
                    return (<MenuItem key={pillar.id} value={pillar.id}> {pillar.name} </MenuItem>)
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={6}>
              <FormControl fullWidth>
                <InputLabel>Intervention</InputLabel>
                <Select defaultValue={0} label="Intervention" value={interventionId} onChange={GetActivitiesByInterventionId} >
                  <MenuItem value={0}><em>None</em></MenuItem>
                  {interventions.map(intervention => {
                    return (<MenuItem key={intervention.id} value={intervention.id}> {intervention.name} </MenuItem>)
                  })}
                </Select>
              </FormControl>
            </Grid>

            <Grid item xs={12} sm={12}>
              <FormControl fullWidth sm={4}>
                <InputLabel>Activity</InputLabel>
                <Select defaultValue={0} label="Activity" value={activityId} onChange={GetQaurterlyReportByActivityId}>
                  <MenuItem value={0}><em>None</em></MenuItem>
                  {activities.map(activity => {
                    return (<MenuItem key={activity.id} value={activity.id}> {activity.name} </MenuItem>)
                  })}
                </Select>
              </FormControl>
            </Grid>

            <QuartelyViewComponent monthlyReport={monthlyReport} />

            <Grid item xs={12}>
              <Typography component="div">
                <Box sx={{ fontWeight: 'bold', m: 1 }}>Quarterly Summary</Box>
              </Typography>
            </Grid>

            {/* {monthlyReport.length > 0 ? (
              <>
                <Grid item xs={12} sm={6}>
                  <TextField required
                    multiline
                    rows={4}
                    fullWidth
                    value={progressNative}
                    onChange={e => setProgressNative(e.target.value)}
                    label="Progress Narrative" />
                </Grid>

                <Grid item xs={12} sm={6}>
                  <TextField required
                    multiline
                    rows={4}
                    fullWidth
                    value={obtacleNarative}
                    onChange={e => setObtacleNarative(e.target.value)}
                    label="Corrective Measures" />
                </Grid>

                <Grid item xs={12}>
                  <Checkbox checked={isComplete}
                    onChange={e => setIsComplete(e.target.checked)}
                    inputProps={{ 'aria-label': 'controlled' }} />Report Completed
                </Grid>

                {achievementsFiles.map(achievementsFile => (
                  <Grid item xs={12} key={achievementsFile.id}>
                    <Button variant="outlined" startIcon={<GetApp />} onClick={() => handleDownload(achievementsFile.fileId)}>
                      {achievementsFile.fileId}
                    </Button>
                  </Grid>
                ))}

              </>
            ) : (
              <Grid item xs={12} >
                <h1>No data available</h1>
              </Grid>
            )} */}

            <>
              <Grid item xs={12} sm={6}>
                <TextField required
                  multiline
                  rows={4}
                  fullWidth
                  value={progressNative}
                  onChange={e => setProgressNative(e.target.value)}
                  label="Progress Narrative" />
              </Grid>

              <Grid item xs={12} sm={6}>
                <TextField required
                  multiline
                  rows={4}
                  fullWidth
                  value={obtacleNarative}
                  onChange={e => setObtacleNarative(e.target.value)}
                  label="Corrective Measures" />
              </Grid>

              <Grid item xs={12}>
                <Checkbox checked={isComplete}
                  onChange={e => setIsComplete(e.target.checked)}
                  inputProps={{ 'aria-label': 'controlled' }} />Report Completed
              </Grid>

              {achievementsFiles.map(achievementsFile => (
                <Grid item xs={12} key={achievementsFile.id}>
                  <Button variant="outlined" startIcon={<GetApp />} onClick={() => handleDownload(achievementsFile.fileId)}>
                    {achievementsFile.fileId}
                  </Button>
                </Grid>
              ))}

            </>

            <Grid container>
              <Grid item xs>
                <Button onClick={() => handleBack()}
                  color='primary'
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}>
                  Back to List
                </Button>
              </Grid>
              <Grid item>
                <Button disabled={isLoading || !isFormValid()}
                  type="submit"
                  color='primary'
                  variant="contained"
                  sx={{ mt: 3, mb: 2 }}>
                  Update
                </Button>
              </Grid>
            </Grid>
          </Grid>
        </Box>
      </Box>
    </Card>
  )
}

export default EditQuarterlyAchievement