import React from 'react';
import NotFoundImage from '../../assests/images/404.jpg'
import { Box } from '@mui/material';
import GridContainer from '../../components/Grid/GridContainer';

const CenteredWrapper = {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    justifyContent: 'flex-end',
    height: '60vh',
};

const ImageStyle = {
    maxWidth: '100%',
    maxHeight: '100%',
    marginTop: '80px', // Adjust the margin top as needed
    marginBottom: '140px'
};

const NotFound = () => {
    return (
        <Box m="20px">
            <GridContainer>

                <img src={NotFoundImage} alt="Page not found" style={ImageStyle} />
                {/* 
                        <div id="info">
                            <Typography variant="h4">This page could not be found</Typography>
                        </div> 
                    */}
            </GridContainer>

        </Box>
    );
}

export default NotFound