import AuthUser from "../../../hooks/AuthUser";
import RestUtilities from "../../RestUtilities";

export default class AccountAuth {
    //NB: Since MUI Table start at 0 and the API start at 1 we need to add 1  

    static isSignedIn() {
        return !!AuthUser.getToken();
    }

    static async signIn(email, password, appId) {
        let data = { "email": email, "appId": appId, "password": password }
        const response = await RestUtilities.post(`Account/Login`, data);
        if (response.content.token !== undefined) {
            AuthUser.setLocations(response.content.locations);
            AuthUser.setUserInfo(response.content)
        }
        return response;
    }


    static async signUp(data) {
        const response = await RestUtilities.post(`Account/Register`, data);
        return response;
    }

    static async adminAddUser(data) {
        debugger;
        const response = await RestUtilities.postForm(`Account/AdminAddUser`, data);
        return response;
    }


    static async forgorPassword(email, appId) {
        let data = { "email": email, "appId": appId }
        const response = await RestUtilities.post(`Account/ForgotPassword`, data);
        if (response.error_content === null) {
            return response.content;
        }
        return response.error_content;
    }

    static async SetNewPassword(userId, newPassword, confirmPassword, code) {
        let data = { "userId": userId, "newPassword": newPassword, "confirmPassword": confirmPassword, "code": code }
        const response = await RestUtilities.patch(`Account/SetNewPassword`, data);
        if (response.error_content === null) {
            return response.content;
        }
        return response.error_content;        
    }

    static async GetAllUsers(searchString, pageNumber, pageSize){
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`Account/GetAllUsers?pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`Account/GetAllUsers?searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetAllUsersWithRoles(searchString, pageNumber, pageSize) {
        let correctPageNumber = pageNumber + 1;
        if (searchString === null || searchString === undefined) {
            return await RestUtilities.get(`Account/GetAllUsersWithRoles?pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
        }
        return await RestUtilities.get(`Account/GetAllUsersWithRoles?searchString=${searchString}&pageNumber=${correctPageNumber}&pageSize=${pageSize}`);
    }

    static async GetAllApps() {
        return await RestUtilities.get(`Account/GetAllApp`);
    }

    static async GetAllRoles() {
        return await RestUtilities.get(`Account/GetAllRoles`);
    }

    static async GetAllForAdminRoles() {
        return await RestUtilities.get(`Account/GetAllForAdminRoles`);
    }


    static signOut() {
        AuthUser.removeUserInfo();
        AuthUser.removeLocations();
    }
}